import {
  Button,
  Flex,
  Spacer,
  Text,
} from '@chakra-ui/react';

export default function LandingPageNavBar({ handleLogin }) {
  const title = 'Simple Lifting App';

  return (
    <Flex
      bg='primary.900'
      h='lg'
      alignItems="center"
      position="fixed"
      top={0}
      left={0}
      right={0}
      pl={6}
      pr={6}
      zIndex="sticky"
      borderBottom="1px"
      borderColor="gray.600"
    >

      <Text color='gray.100' fontSize="sm" fontWeight="bold" mt='3'>
        {title}
      </Text>

      <Spacer />

      <Button
        color='gray.200'
        fontSize="sm"
        fontWeight="bold"
        mt='3'
        variant="unstyled"
        _hover={{ color: 'gray.100' }}
        onClick={handleLogin}
      >
        Log in
      </Button>

    </Flex>
  );
}
