import React, { useState } from "react";
import {
  Button,
  Box,
  HStack,
  Divider,
  Spacer,
} from "@chakra-ui/react";
import { useDataManager } from "../../data/useDataManager";
import { useNavigate } from "react-router-dom";
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { ROUTES } from "../../config/routes";
import LoadingSpinner from "../common/LoadingSpinner";

function WorkoutListPage() {
  const navigate = useNavigate();
  const dataManager = useDataManager();

  const [loading] = useState(false);

  const { workouts } = dataManager;

  const handleWorkoutClicked = (workoutId) => {
    navigate(`${ROUTES.WORKOUT_LIST}/${workoutId}`);
  };

  const handleCreateWorkoutClicked = () => {
    const input = prompt("Enter the name of the workout");
    if (!input) {
      return;
    }
    const workoutName = input.trim().toLocaleLowerCase();
    if (workoutName.length > 200) {
      alert('Lift type name too long');
      return;
    }
    if (workoutName.length === 0) {
      alert('Lift type name too short');
      return;
    }

    const { error } = dataManager.createWorkout(workoutName);
    if (error) {
      alert('There was an issue creating this workout');
    }
  }

  const handleDeleteClicked = (workoutId) => {
    const input = prompt('Enter "delete" to delete this workout');
    if (!input) {
      return
    }
    if (input.toLocaleLowerCase() === 'delete') {
      dataManager.deleteWorkout(workoutId);
    } else {
      alert('Did not delete workout');
    }
  }

  const handleOnEditClicked = (workoutId) => {
    const input = prompt('Select a new name for the workout');
    if (!input || !input.trim()) {
      return;
    }
    const newName = input.toLocaleLowerCase().trim();

    const { error } = dataManager.editWorkout(workoutId, newName);
    if (error) {
      alert('Issue renaming workout');
    }
  }

  return <>
    <Box pt='60px' pb='60px'>

      {loading
        ? <LoadingSpinner description={'Generating your workout...'} />
        :
        workouts.map(workout => {
          return <Box key={workout.id}>
            <HStack py={5} px={10} alignItems="center">
              <Box
                w='100%'
                textTransform="capitalize"
                onClick={() => handleWorkoutClicked(workout.id)}
              >
                {workout.name}
              </Box>

              <Spacer />
              <EditIcon onClick={() => handleOnEditClicked(workout.id)} />
              <Spacer />
              <DeleteIcon onClick={() => handleDeleteClicked(workout.id)} />
            </HStack>
            <Divider />
          </Box>
        })}

      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        position="fixed"
        bottom={0}
        left={0}
        w="100%"
        p={1}
        zIndex={2}
        bg='white'
      >
        <Button
          disabled={loading}
          type="submit"
          colorScheme="blue"
          size="lg"
          w="250px"
          onClick={handleCreateWorkoutClicked}
          mx="2"
        >
          Create Workout
        </Button>

      </Box>
    </Box>
  </>
}

export default WorkoutListPage;
