import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Spacer,
  HStack,
  Button,
  Textarea,
  Select,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import { format } from "date-fns";

import { useDataManager } from "../../data/useDataManager";

function LiftDetail({ handleCreate, handleUpdate, handleDelete, lift }) {
  const dataManager = useDataManager();
  // const auth = useAuth();
  // const { postRequest } = usePostRequest(API_ENDPOINTS.POST_UPLOAD_VIDEO_URL);

  const { sortedLiftTypes, liftTypes } = dataManager;
  const [liftType, setLiftType] = useState(lift?.lift_type || "");
  const [weight, setWeight] = useState(lift?.weight || 0);
  const [reps, setReps] = useState(lift?.reps || "");
  const [notes, setNotes] = useState(lift?.notes || "");
  const [date, setDate] = useState(lift ? new Date(lift.created_at) : new Date());
  // const [videoFile, setVideoFile] = useState(undefined)


  const onUpdateClicked = async () => {
    if (lift) {

      // if (videoFile) {

      //   const { data, error } = await postRequest({ lift_id: lift.id, filename: videoFile.name }, auth?.session.access_token);
      //   if (error) {
      //     alert(`Failed to get upload url`)
      //     return;
      //   }

      //   if (!data.upload_url) {
      //     alert(`Invalid upload url`)
      //     return;
      //   }
      //   const url = data.upload_url;

      //   const { error: error2 } = await dataManager.uploadLiftVideo(lift.id, videoFile, url);

      //   if (error2) {
      //     alert('Failed to upload video')
      //     return;
      //   }
      // }

      var liftTypeId;
      liftTypes.forEach(lt => {
        if (lt.name === liftType) {
          liftTypeId = lt.id;
        }
      })

      handleUpdate({ ...lift, lift_type: liftType, lift_type_id: liftTypeId, weight, reps, notes, created_at: date });
    } else {
      alert('No lift to update')
    }
  }

  const onCopyClicked = () => {
    var liftTypeId;
    liftTypes.forEach(lt => {
      if (lt.name === liftType) {
        liftTypeId = lt.id;
      }
    })
    if (lift && liftTypeId) {
      // Don't provide a date / close to current time for now
      handleCreate(liftType, liftTypeId, weight, reps, notes)
    } else {
      alert('No lift to copy')
    }
  }

  const onCompleteClicked = () => {
    var liftTypeId;
    liftTypes.forEach(lt => {
      if (lt.name === liftType) {
        liftTypeId = lt.id;
      }
    })
    if (lift && liftTypeId) {
      handleUpdate({ ...lift, lift_type: liftType, lift_type_id: liftTypeId, weight, reps, notes, created_at: new Date(), status: 'COMPLETE' })
    } else {
      alert('No lift to complete')
    }
  }

  // const handleVideoFileChange = (event) => {
  //   setVideoFile(event.target.files[0]);
  // }

  const handleDateChange = (event) => setDate(new Date(event.target.value));
  const handlWeightChange = (value) => setWeight(value);;
  const handleRepsChange = (value) => setReps(value);
  const handleNotesChange = (event) => setNotes(event.target.value);
  const handleLiftTypeChange = (event) => {
    if (event.target.value === 'create-new*') {

      const input = prompt("Enter the lift type you want to create");
      if (!input) {
        return;
      }
      const liftType = input.trim().toLocaleLowerCase();
      if (liftType.length > 100) {
        alert('Lift type name too long');
        return;
      }
      const { error } = dataManager.createLiftType(liftType, liftType);
      if (error) {
        alert('There was an issue creating this lift type');
      }


      return;
    }
    setLiftType(event.target.value)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    var liftTypeId;
    liftTypes.forEach(lt => {
      if (lt.name === liftType) {
        liftTypeId = lt.id;
      }
    })

    if (!liftType || !liftTypeId || !weight || !reps) {
      alert("Requires lift type, weight, and reps")
      return;
    }

    handleCreate(liftType, liftTypeId, weight, reps, notes, date);
  };

  const defaultDateTime = format(date, "yyyy-MM-dd'T'HH:mm");

  const liftTypeOptions = sortedLiftTypes?.map((liftType) => <option key={liftType.id} value={liftType.name}>{liftType.name}</option>) || [];
  if (lift && !sortedLiftTypes.map(liftType => liftType.name).includes(lift.lift_type)) {
    liftTypeOptions.push(<option key={lift.lift_type} value={lift.lift_type}>{lift.lift_type}</option>)
  }
  liftTypeOptions.push(<option key="divider" disabled>───────</option>)
  liftTypeOptions.push(<option key="create-new" value="create-new*">Create New Lift Type</option>)

  return (
    <Flex width='100%' justifyContent='center'>
      <Box maxWidth='600px' width="100%" mt={6} pl={30} pr={30} >
        <FormControl pt={10} mb={5} display="block">
          <FormLabel>Date</FormLabel>
          <Input
            placeholder="Select Date and Time"
            size="md"
            type="datetime-local"
            value={defaultDateTime}
            onChange={handleDateChange}
          />
        </FormControl>

        <Box as="form" onSubmit={handleSubmit}>
          <FormControl mb={5} display="block">
            <FormLabel>Lift Type</FormLabel>
            <Select
              placeholder="Select lift type"
              value={liftType}
              onChange={handleLiftTypeChange}
              size="lg"
              autoComplete="off"
            >
              {liftTypeOptions}
            </Select>
          </FormControl>

          <FormControl mb={5} display="block">
            <FormLabel>Weight</FormLabel>
            <NumberInput min={0} max={3000} defaultValue={weight || undefined} onChange={handlWeightChange}>
              <NumberInputField />
            </NumberInput>
          </FormControl>

          <FormControl mb={5} display="block">
            <FormLabel>Reps</FormLabel>
            <NumberInput min={0} max={200} defaultValue={reps} onChange={handleRepsChange}>
              <NumberInputField />
            </NumberInput>
          </FormControl>

          <FormControl display="block">
            <FormLabel>Notes</FormLabel>
            <Textarea
              defaultValue={notes}
              onChange={handleNotesChange}
            >
            </Textarea>
          </FormControl>

          {/* Disabled for now */}
          {/* <FormControl>
            <FormLabel>Video</FormLabel>
            <Input
              type="file"
              onChange={handleVideoFileChange}
              sx={{
                "::file-selector-button": {
                  height: 10,
                  padding: 0,
                  mr: 4,
                  background: "none",
                  border: "none",
                  fontWeight: "bold",
                },
              }}
            />
          </FormControl>*/}

          <HStack mt={5}>

            {!!lift
              ? lift.status === 'COMPLETE' ?
                <Button onClick={onUpdateClicked} colorScheme="blue" size="lg">
                  Update
                </Button>
                :
                <Button onClick={onCompleteClicked} colorScheme="blue" size="lg">
                  Complete
                </Button>
              :
              <Button type="submit" colorScheme="blue" size="lg">
                Create
              </Button>
            }
            {!!lift &&
              <>
                {lift.status === 'COMPLETE' && <Button onClick={onCopyClicked} colorScheme="green" size="lg">
                  Copy
                </Button>}
                <Spacer></Spacer>
                <Button onClick={() => handleDelete(lift.id)} colorScheme="red" size="lg">
                  Delete
                </Button>
              </>
            }

          </HStack>
        </Box>
      </Box>
    </Flex>
  );
}

export default LiftDetail;
