// library constants
export const EVENT_TYPE_COMPLETION = 'completion'

export const EVENT_STATUS_SUCCESS = 'success';
export const EVENT_STATUS_FAIL = 'fail';


// app constants

export const LIFT_COMPLETE = 'Lift Complete';
export const LIFT_CREATE = 'Lift Create';
export const LIFT_DELETE = 'Lift Delete';
export const LIFT_UPDATE = 'Lift Update';
export const LIFT_COPY = 'Lift Copy';
