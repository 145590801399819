import { useAuth } from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../config/routes";
import NavBar from "./NavBar";
import SideNav from "./SideNav";
import { useDisclosure } from "@chakra-ui/react";
import React, { useRef } from 'react';


function RequireAuth({ children }) {
  let auth = useAuth();
  const btnRef = useRef()
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (!auth.user) {
    return <Navigate to={ROUTES.HOME} />
  }

  return <>
    <NavBar iconClickRef={btnRef} onIconClick={onOpen} />
    <SideNav isOpen={isOpen} onClose={onClose} btnRef={btnRef} />
    {children}
  </>
}

export default RequireAuth;
