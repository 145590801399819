import { useAuth } from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../config/routes";


function RequireNonAuth({ children }) {
  let auth = useAuth();

  if (!!auth.user) {
    return <Navigate to={ROUTES.MAIN} />
  }

  return children;
}

export default RequireNonAuth;
