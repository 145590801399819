import React from 'react';
import { useNavigate } from "react-router-dom";
import { Box, VStack, Text, Button, Divider, HStack } from '@chakra-ui/react';
import { ROUTES } from '../../config/routes';
import { useDataManager } from '../../data/useDataManager';

export const GoalListPage = () => {

  const navigate = useNavigate();
  const { goals } = useDataManager();

  const handleGoalClicked = (goalId) => {
    navigate(`${ROUTES.GOAL_DETAIL_BASE}/${goalId}`);
  };

  const handleCreateGoal = () => {
    navigate(ROUTES.GOAL_CREATE);
  };

  return (
    <Box pt="60px" pb="60px">
      <Box >
        {goals.length === 0
          ? <VStack spacing={4} alignItems="center" mt={10}>
            <Text fontSize="2xl" fontWeight="bold">
              Currently no goals
            </Text>
            <Text fontSize="lg" color="gray.500">
              Click the button below to create a new goal.
            </Text>
          </VStack>
          : goals.map((goal, index) => (
            <Box key={goal.id}>
              <HStack py={5} px={10} alignItems="center">
                <Box
                  w='100%'
                  textTransform="capitalize"
                  onClick={() => { handleGoalClicked(goal.id) }}
                >
                  {goal.lift_type} - {goal.weight} x {goal.reps}
                </Box>
              </HStack>
              <Divider />
            </Box>
          ))}
      </Box>
      <Box
        position="fixed"
        bottom={0}
        left={0}
        w="100%"
        p={1}
        zIndex={2}
        bg="white"
        borderTop="1px solid"
        borderColor="gray.200"
        style={{ display: 'flex' }}
      >
        <Button
          colorScheme="blue"
          size="lg"
          w="250px"
          onClick={handleCreateGoal}
          mx="auto"
        >
          Create Goal
        </Button>
      </Box>
    </Box>
  );
};
