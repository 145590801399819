import { Button, Container, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/routes";
import FooterV2 from "./FooterV2";
import LandingPageNavBar from "./LandingPageNavBar";

const LandingPageV2 = () => {
  const pt = {
    base: '140px',
    md: '270px'
  }

  const padding = {
    base: 8,
    md: 5
  }

  const navigate = useNavigate();

  const onLoginClick = () => navigate(ROUTES.SIGN_IN);
  const onGetStartedClick = () => navigate(ROUTES.SIGN_UP);

  return (
    <>
      <LandingPageNavBar handleLogin={onLoginClick} />
      <Flex direction="column" minHeight="100vh">

        <Container pt={pt} pl={padding} pr={padding} centerContent flexGrow={1}>
          <VStack spacing={10}>
            <Heading
              as="h1"
              fontSize='2.5xl'
              fontWeight={600}
              textAlign="center"
              color="gray.100"
              sx={{
                fontFamily: "Montserrat"
              }}
            >

              Simple Lifting App helps you get stronger
            </Heading>

            <Text color="gray.100" fontSize='md' textAlign='center' >
              Don't leave your gains to memory.
              Record your reps, track your progress,
              and watch your strength journey unfold.
            </Text>

            <Button
              bg='primary.600'
              color='gray.100'
              height='md'
              width='2.5lg'
              borderRadius='2xl'
              fontWeight='medium'
              _hover={{
                bg: 'primary.500',
              }}
              onClick={onGetStartedClick}
            >
              Get Started
            </Button>
          </VStack>
        </Container>
        <FooterV2 />
      </Flex>
    </>
  );
};


export default LandingPageV2;
