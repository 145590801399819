import {
  Alert,
  AlertDescription,
  Box,
} from '@chakra-ui/react';

export function ToastAlert({ message, status }) {



  return <Alert
    status={status}
    variant="solid"
    // bg='error.600'
    flexDirection="row"
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    height='md'
    borderRadius="md"
    // color='gray.100'
    fontWeight='bold'
    boxShadow="lg"
  >
    <Box flex="1">
      <AlertDescription display="block">
        {message}
      </AlertDescription>
    </Box>
  </Alert>

}
