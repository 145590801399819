import { v4 as uuidv4 } from 'uuid';
import { EVENT_STATUS_FAIL, EVENT_STATUS_SUCCESS, EVENT_TYPE_COMPLETION } from './constants';

// TODO: move to config
const API_URL = 'https://client-action-tracker.vercel.app/events';
const API_KEY = '2QemT06CchLrtfCHBM08U5aKAFqhjRKZXF1A36OXdbo=';

async function postEvent(payload) {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'api-key': API_KEY,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ events: [payload] })
  });

  if (!response.ok) {
    console.error(`Event logging failed with status ${response.status}`);
  }
}

function useTrackAction() {
  const events = {};

  const trackStart = (eventName, userId) => {
    const eventId = uuidv4();
    const now = new Date();

    events[eventId] = {
      event_id: eventId,
      event_name: eventName,
      event_type: EVENT_TYPE_COMPLETION,
      client_created_at: now,
      client_user_id: userId,
      properties: {}
    }

    return eventId;
  }

  const uploadEvent = (eventId, status) => {
    if (!events[eventId]) {
      console.warn(`eventId ${eventId} is not being tracked`)
      return;
    }

    const now = new Date();
    events[eventId] = {
      ...events[eventId],
      event_status: status,
      client_completed_at: now,
    }
    console.log(`Uploading event: ${JSON.stringify(events[eventId])}`)
    postEvent(events[eventId])
    delete events[eventId];
  }

  const trackSuccess = (eventId) => {
    uploadEvent(eventId, EVENT_STATUS_SUCCESS);
  }

  const trackFail = (eventId) => {
    uploadEvent(eventId, EVENT_STATUS_FAIL);
  }

  return {
    trackStart,
    trackSuccess,
    trackFail,
  }
}

export default useTrackAction;
