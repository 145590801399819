import React from 'react';
import {
  Box,
  Text,
  CircularProgress,
  VStack,
} from '@chakra-ui/react';


const LoadingSpinner = ({ description }) => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex={3}
      backgroundColor="rgba(255, 255, 255, 0.8)"
    >
      <VStack>
        <CircularProgress isIndeterminate color="blue.500" />

        <Text>
          {description}
        </Text>

      </VStack>
    </Box>
  );
};

export default LoadingSpinner;
