import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Button,
  Select,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDataManager } from "../../data/useDataManager";

function LiftTemplate({ workoutId, liftTemplate }) {
  const navigate = useNavigate();
  const dataManager = useDataManager();
  const { liftTypes } = dataManager;

  const [liftType, setLiftType] = useState(liftTemplate?.name || "");
  const [weight, setWeight] = useState(liftTemplate?.weight || "");
  const [sets, setSets] = useState(liftTemplate?.sets || "");
  const [reps, setReps] = useState(liftTemplate?.reps || "");

  const handleLiftTypeChange = (event) => setLiftType(event.target.value);
  const handlWeightChange = (value) => setWeight(value);;
  const handleSetsChange = (value) => setSets(value);
  const handleRepsChange = (value) => setReps(value);

  const onCreateClicked = () => {
    if (!workoutId) {
      alert('Cannot create lift template without workoutId');
      return;
    }

    if (!liftType || !weight || !sets || !reps) {
      alert('Please fill out all fields');
      return;
    }

    const { error } = dataManager.createLiftTemplate(workoutId, liftType, weight, sets, reps);
    if (error) {
      alert('Failed to create lift template');
      console.log(error);
    } else {
      navigate(-1);
    }
  }

  const onUpdateClicked = () => {
    const { error } = dataManager.updateLiftTemplate({ ...liftTemplate, name: liftType, weight, sets, reps });
    if (error) {
      alert('Failed to update lift template');
      console.log(error);
    } else {
      navigate(-1);
    }
  }

  const liftTypeOptions = liftTypes.map((liftType) => <option key={liftType.id} value={liftType.name}>{liftType.name}</option>)
  if (liftTemplate && !liftTypes.map(lt => lt.name).includes(liftTemplate.name)) {
    liftTypeOptions.push(<option key={liftTemplate.name} value={liftTemplate.name}>{liftTemplate.name}</option>)
  }

  return (
    <Flex width='100%' justifyContent='center'>
      <Box maxWidth='600px' width="100%" mt={6} pl={30} pr={30} >
        <FormControl pt={10} mb={5} display="block">
          <FormLabel>Lift Type</FormLabel>
          <Select
            placeholder="Select lift type"
            value={liftType}
            onChange={handleLiftTypeChange}
            size="lg"
            autoComplete="off"
          >
            {liftTypeOptions}
          </Select>
        </FormControl>

        <FormControl mb={5} display="block">
          <FormLabel>Weight</FormLabel>
          <NumberInput min={0} max={3000} defaultValue={weight || undefined} onChange={handlWeightChange}>
            <NumberInputField />
          </NumberInput>
        </FormControl>

        <FormControl mb={5} display="block">
          <FormLabel>Sets</FormLabel>
          <NumberInput min={0} max={100} defaultValue={sets} onChange={handleSetsChange}>
            <NumberInputField />
          </NumberInput>
        </FormControl>

        <FormControl mb={5} display="block">
          <FormLabel>Reps</FormLabel>
          <NumberInput min={0} max={200} defaultValue={reps} onChange={handleRepsChange}>
            <NumberInputField />
          </NumberInput>
        </FormControl>

        <HStack mt={5}>

          {
            liftTemplate
              ? <Button onClick={onUpdateClicked} colorScheme="green" size="lg">
                Update
              </Button>
              : <Button onClick={onCreateClicked} colorScheme="blue" size="lg">
                Create
              </Button>
          }


        </HStack>
      </Box>
    </Flex >
  );
}

export default LiftTemplate;
