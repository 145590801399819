import React from 'react';
import './index.css';
import App from './App';
import { createRoot } from "react-dom/client";
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import { extendTheme, ChakraProvider } from '@chakra-ui/react'
import ErrorBoundary from './components/common/ErrorBoundary';

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { KEYS } from './config/keys';
import customTheme from './theme/theme';

import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/700.css";

Sentry.init({
  dsn: KEYS.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  // tracesSampleRate: 1.0,
  tracesSampleRate: 0.0,
});

const theme = extendTheme({
  ...customTheme
})


createRoot(document.getElementById("root")).render(
  <ChakraProvider theme={theme}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </ChakraProvider>
);

reportWebVitals(sendToVercelAnalytics);
