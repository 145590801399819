import { useContext, useState, useEffect, createContext } from 'react';
import { supabase } from '../supabaseClient';

// create a context for authentication
const AuthContext = createContext(undefined);

const guestExpirationKey = 'simpleliftingapp.guestexpiration';

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [session, setSession] = useState();
  const [loading, setLoading] = useState(true);

  const signOut = async () => {
    setUser(undefined);
    setSession(undefined);
    setLoading(true);
    return supabase.auth.signOut()
  }

  useEffect(() => {
    const setData = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (!session) {
        setLoading(false);
        return;
      }
      const { error: userError } = await supabase.auth.getUser()

      if (error) throw error;

      if (userError && userError.status === 404) {
        signOut();
      }

      setSession(session)
      setUser(session?.user)
      setLoading(false);
    };

    const { data: listener } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setUser(session?.user)
      setLoading(false)
    });

    setData();

    return () => {
      listener?.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const checkExpiry = () => {
      const expiration = localStorage.getItem(guestExpirationKey);
      if (expiration && new Date().getTime() >= parseInt(expiration, 10)) {
        supabase.auth.signOut();
      }
    };

    let intervalId;
    if (user && user.user_metadata?.guest) {
      checkExpiry();
      // check every 5 minutes
      intervalId = setInterval(checkExpiry, 60 * 1000 * 5);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };

  }, [user, session])

  const value = {
    session,
    user,
    signOut,
    // signOut: async () => {
    //   setUser(undefined);
    //   setSession(undefined);
    //   setLoading(true);
    //   return supabase.auth.signOut()
    // },
    signIn: (email, password) => {
      return supabase.auth.signInWithPassword({ email, password });
    },
    googleSignIn: async () => {
      return await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
        },
      })
    },
    setSession: (access_token, refresh_token) => {
      supabase.auth.setSession({
        access_token,
        refresh_token,
      });

      // in one hour
      const guestExpiration = new Date(Date.now() + 60 * 60 * 1000).getTime();
      localStorage.setItem(guestExpirationKey, guestExpiration);
    }
  };

  // use a provider to pass down the value
  return (
    <AuthContext.Provider value={value} >
      {!loading && children
      }
    </AuthContext.Provider >
  );
};

// export the useAuth hook
export const useAuth = () => {
  return useContext(AuthContext);
};
