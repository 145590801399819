import { createContext, useState, useContext } from 'react';

const NavBarContext = createContext(null);

export const useNavBarContext = () => useContext(NavBarContext);

export const NavBarProvider = ({ children }) => {
  // TODO: rename to navbar action
  const [navBarElement, setNavBarElement] = useState(null);

  return (
    <NavBarContext.Provider value={{ navBarElement, setNavBarElement }}>
      {children}
    </NavBarContext.Provider>
  );
};
