
const customTheme = {
  fonts: {
    heading: "Lato, sans-serif",
    body: "Lato, sans-serif",
  },
  space: {
    1: '1px',
    2: '2px',
    3: '4px',
    4: '8px',
    5: '12px',
    6: '16px',
    7: '24px',
    8: '32px',
    9: '48px',
    10: '64px',
    11: '96px',
    12: '128px',
    13: '192px',
  },
  colors: {
    primary: {
      100: "#B2D9FF",
      200: "#66B2FF",
      300: "#3399FF",
      400: "#1F8CF9",
      500: "#0D80F2",
      600: "#0A66C2",
      700: "#033363",
      800: "#00264D",
      900: "#0A1620",
    },
    gray: {
      100: "#D8D9DA",
      200: "#B0B3B5",
      300: "#7C8083",
      400: "#636669",
      500: "#57595C",
      600: "#4A4D4F",
      700: "#313335",
      800: "#252627",
      900: "#141415",
    },
    coolGray: {
      100: "#D3D9DF",
      200: "#A7B5BE",
      300: "#6C8293",
      400: "#576675",
      500: "#4C5767",
      600: "#414F58",
      700: "#2B333B",
      800: "#20262C",
      900: "#111118",
    },
    error: {
      100: "#F7BBBA",
      200: "#F28E8C",
      300: "#ED615E",
      400: "#DE4845",
      500: "#AD221F",
      600: "#9C1916",
      700: "#5C0C0A",
      800: "#4D0200",
      900: "#290100",
    }
  },
  styles: {
    global: {
      body: {
        bg: 'primary.900',
      }
    }
  },
  fontSizes: {
    "3xs": "10px",
    "2xs": "12px",
    xs: "14px",
    sm: "16px",
    md: "18px",
    lg: "20px",
    xl: "24px",
    "2xl": "32px",
    "2.5xl": "40px",
    "3xl": "48px",
    "4xl": "64px",
  },
  sizes: {
    "3xs": "12px",
    "2xs": "16px",
    xs: "24px",
    sm: "32px",
    md: "48px",
    lg: "64px",
    "1.5lg": "96px",
    "2lg": "128px",
    "2.5lg": "180px",
    "3lg": "256px",
    "4lg": "400px",
    xl: "512px",
    "2xl": "768px",
    "3xl": "1024px",
    "4xl": "1536px",
  },
  radii: {
    none: '0',
    sm: '2px',
    base: '4px',
    md: '8px',
    lg: '10px',
    "2lg": '12px',
    xl: '16px',
    "2xl": '24px',
  },
}

export default customTheme;
