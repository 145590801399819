import React, { useState } from "react";

import {
  Box,
  FormControl,
  HStack,
  Spacer,
  FormLabel,
  Tbody,
  Tfoot,
  Table,
  NumberInput,
  NumberInputField,
  Th,
  Td,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { plateMath } from "../utils/utils";

function PlateMathPage() {

  const [weight, setWeight] = useState(0);

  const weights = weight > 0 ? plateMath(weight) : [];
  const total = weights.reduce((acc, w) => acc + (w.weight * w.count) * 2, 0);

  return <>
    <Box pt='60px'>
      <FormControl pt={5} mb={5} display="block">
        <HStack px={5}>
          <FormLabel>Weight</FormLabel>
          <Spacer />
          <NumberInput
            placeholder="Enter weight"
            min={45}
            max={1000}
            defaultValue={weight === 0 ? '' : weight}
            onChange={setWeight}
          >
            <NumberInputField />
          </NumberInput>
        </HStack>
      </FormControl>

      <Table size="md" variant='simple'>
        <Thead key='header'>
          <Tr>
            <Th>Plate</Th>
            <Th isNumeric>Per Side</Th>
            <Th isNumeric>Total</Th>
            <Th isNumeric>Weight</Th>
          </Tr>
        </Thead>

        <Tbody>
          {weights.map((weight) => (
            weight.type === 'bar' ? (
              <Tr key={`${weight.type}-${weight.weight}`}>
                <Td>Bar</Td>
                <Td pr={10} textAlign={'right'}></Td>
                <Td pr={10} textAlign={'right'}>1</Td>
                <Td pr={10} textAlign={'right'}>{weight.count * weight.weight * 2}</Td>
              </Tr>
            ) : (
              <Tr key={`${weight.type}-${weight.weight}`}>
                <Td>{weight.weight}</Td>
                <Td pr={10} textAlign={'right'}>{weight.count}</Td>
                <Td pr={10} textAlign={'right'}>{weight.count * 2}</Td>
                <Td pr={10} textAlign={'right'}>{weight.weight * weight.count * 2}</Td>
              </Tr>)
          )
          )}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>Total</Th>
            <Th></Th>
            <Th></Th>
            <Th pr={10} isNumeric>{total}</Th>
          </Tr>
        </Tfoot>
      </Table>
    </Box>
  </>
}

export default PlateMathPage;
