import React from "react";
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { ROUTES } from "./config/routes";
import LiftCreatePage from "./components/lift/LiftCreatePage";
import { AuthProvider } from "./hooks/useAuth";
import RequireAuth from "./components/nav/RequireAuth";
import { NavBarProvider } from './components/nav/NavBarContext';
import RequireNonAuth from "./components/nav/RequireNonAuth";
import LiftDetailPage from "./components/lift/LiftDetailPage";
import { DataManagerProvider } from "./data/useDataManager";
import LiftTypeListPage from "./components/LiftTypeListPage";
import PlateMathPage from "./components/PlateMathPage";
import WorkoutListPage from "./components/workout/WorkoutListPage";
import WorkoutDetailPage from "./components/workout/WorkoutDetailPage";
import LiftTemplateDetailPage from "./components/lifttemplate/LiftTemplateDetailPage";
import LiftTemplateCreatePage from "./components/lifttemplate/LiftTemplateCreatePage";
import ChartPage from "./components/ChartPage";
import { GoalListPage } from "./components/goal/GoalListPage";
import GoalCreatePage from "./components/goal/GoalCreatePage";
import GoalEditPage from "./components/goal/GoalEditPage";
import WeightCreatePage from "./components/weight/WeightCreatePage";
import WeightListPage from "./components/weight/WeightListPage";
import TrackAction from './components/metrics/TrackAction';
import MainPageV2 from "./components/mainV2/MainPageV2";
import LandingPageV2 from "./components/landing/LandingPageV2";
import SignInPageV2 from "./components/account/SignInPageV2";


function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <DataManagerProvider>
          <NavBarProvider>

            <TrackAction name='App Loaded' />

            <Routes>

              <Route path={ROUTES.SIGN_IN} element={
                <RequireNonAuth>
                  <SignInPageV2 isCreateMode={false} />
                </RequireNonAuth>
              } />
              <Route path={ROUTES.HOME} element={
                <RequireNonAuth>
                  <LandingPageV2 />
                </RequireNonAuth>
              } />

              <Route path={ROUTES.SIGN_UP} element={
                <RequireNonAuth>
                  <SignInPageV2 isCreateMode={true} />
                </RequireNonAuth>
              } />

              <Route path={ROUTES.MAIN} element={
                <RequireAuth>
                  <MainPageV2 />
                </RequireAuth>
              } />

              <Route path={ROUTES.LIFT_CREATE} element={
                <RequireAuth>
                  <LiftCreatePage />
                </RequireAuth>
              } />
              <Route path={ROUTES.LIFT_TYPE_LIST} element={
                <RequireAuth>
                  <LiftTypeListPage />
                </RequireAuth>
              } />
              <Route path={ROUTES.WORKOUT_LIST} element={
                <RequireAuth>
                  <WorkoutListPage />
                </RequireAuth>
              } />
              <Route path={`${ROUTES.WORKOUT_LIST}/:workoutId`} element={
                <RequireAuth>
                  <WorkoutDetailPage />
                </RequireAuth>
              } />

              <Route path={ROUTES.GOAL_LIST} element={
                <RequireAuth>
                  <GoalListPage />
                </RequireAuth>
              } />
              <Route path={ROUTES.GOAL_CREATE} element={
                <RequireAuth>
                  <GoalCreatePage />
                </RequireAuth>
              } />
              <Route path={`${ROUTES.GOAL_DETAIL_BASE}/:goalId`} element={
                <RequireAuth>
                  <GoalEditPage />
                </RequireAuth>
              } />

              <Route path={`${ROUTES.LIFT_TEMPLATE_CREATE}/:workoutId`} element={
                <RequireAuth>
                  <LiftTemplateCreatePage />
                </RequireAuth>
              } />
              <Route path={`${ROUTES.LIFT_TEMPLATE_DETAIL}/:liftTemplateId`} element={
                <RequireAuth>
                  <LiftTemplateDetailPage />
                </RequireAuth>
              } />
              <Route path={`${ROUTES.LIFT_DETAIL_BASE}/:liftId`} element={
                <RequireAuth>
                  <LiftDetailPage />
                </RequireAuth>
              } />
              <Route path={ROUTES.PLATE_MATH} element={
                <RequireAuth>
                  <PlateMathPage />
                </RequireAuth>
              } />
              <Route path={ROUTES.CHART} element={
                <RequireAuth>
                  <ChartPage />
                </RequireAuth>
              } />
              <Route path={ROUTES.WEIGHT_CREATE} element={
                <RequireAuth>
                  <WeightCreatePage />
                </RequireAuth>
              } />
              <Route path={ROUTES.WEIGHT_LIST} element={
                <RequireAuth>
                  <WeightListPage />
                </RequireAuth>
              } />
            </Routes>

          </NavBarProvider>
        </DataManagerProvider>
      </AuthProvider>
    </BrowserRouter >
  );
}

export default App;
