import React, { useState } from 'react';
import { Flex, Box, Select, useTheme, FormLabel, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useDataManager } from '../data/useDataManager';
import { estimateMaxRep, getDateFromStr, roundToNearestN } from '../utils/utils';

const DATE_INTERVAL = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly'
};

const INTERVAL_OPTIONS = [
  <option key={DATE_INTERVAL.DAILY} value={DATE_INTERVAL.DAILY}>Daily</option>,
  <option key={DATE_INTERVAL.WEEKLY} value={DATE_INTERVAL.WEEKLY}>Weekly</option>,
  <option key={DATE_INTERVAL.MONTHLY} value={DATE_INTERVAL.MONTHLY}>Monthly</option>,
];

const CHART_TYPE = {
  MAX_REP: 'max_rep',
  VOLUME: 'volume',
  LIFT_GRID: 'lift_grid',
  WEIGHT: 'weight',
  MAX_PERCENT: 'max_percent',
};

const CHART_OPTIONS = [
  <option key='volume' value={CHART_TYPE.VOLUME}>Volume</option>,
  <option key='max-rep' value={CHART_TYPE.MAX_REP}>Max Rep</option>,
  <option key='lift-grid' value={CHART_TYPE.LIFT_GRID}>Lift Grid</option>,
  <option key='weight' value={CHART_TYPE.WEIGHT}>Weight</option>,
  <option key='max-percent' value={CHART_TYPE.MAX_PERCENT}>Max Percent</option>,
];

const WEIGHT_DATE_RANGE = {
  ONE_MONTH: '1_month',
  ONE_YEAR: '1_year',
  FIVE_YEARS: '5_years',
};

const WEIGHT_DATE_RANGE_OPTIONS = [
  <option key={WEIGHT_DATE_RANGE.ONE_MONTH} value={WEIGHT_DATE_RANGE.ONE_MONTH}>1 Month</option>,
  <option key={WEIGHT_DATE_RANGE.ONE_YEAR} value={WEIGHT_DATE_RANGE.ONE_YEAR}>1 Year</option>,
  <option key={WEIGHT_DATE_RANGE.FIVE_YEARS} value={WEIGHT_DATE_RANGE.FIVE_YEARS}>5 Years</option>,
];

const dateFormatter = (dateString, interval) => {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);

  if (interval === DATE_INTERVAL.MONTHLY) {
    return date.toLocaleDateString('en-US', { month: 'numeric', year: '2-digit' });
  }

  return date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: '2-digit' });
};

const defaultYTicks = [0, 10, 20, 30];
function generateYticks(minY, maxY) {
  if (!isFinite(Math.abs(minY)) || !isFinite(Math.abs(maxY))) {
    return defaultYTicks;
  }

  const range = maxY - minY;

  const step = Math.ceil((range / 3) / 10) * 10
  const start = Math.floor(minY / 10) * 10

  const ticks = [];
  for (let i = 0; i < 4; i++) {
    ticks.push(start + i * step);
  }
  return ticks;
}

const generateXTicks = (minX, maxX, interval) => {
  const xTicks = [];
  const date = new Date(minX);
  const endDate = new Date(maxX);

  switch (interval) {
    case DATE_INTERVAL.DAILY:
      while (date <= endDate) {
        xTicks.push(date.getTime());
        date.setDate(date.getDate() + 1);
      }
      break;
    case DATE_INTERVAL.WEEKLY:
      while (date <= endDate) {
        xTicks.push(date.getTime());
        date.setDate(date.getDate() + 7);
      }
      break;
    case DATE_INTERVAL.MONTHLY:
      while (date <= endDate) {
        xTicks.push(date.getTime());
        date.setMonth(date.getMonth() + 1);
      }
      break;
    default:
      break;
  }

  return xTicks;
};


const ChartPage = () => {
  const { lifts, liftTypes, weights } = useDataManager();
  const [selectedLiftType, setSelectedLiftType] = useState('squat');
  const [selectedReps, setSelectedReps] = useState('');

  const [chartType, setChartType] = useState(CHART_TYPE.VOLUME);
  const [dateInterval, setDateInterval] = useState(DATE_INTERVAL.DAILY);
  const [weightDateRange, setWeightDateRange] = useState(WEIGHT_DATE_RANGE.ONE_MONTH);

  const matchingLifts = lifts
    .filter((lift) => (selectedLiftType ? lift.lift_type === selectedLiftType : true));

  // for chart
  matchingLifts.forEach(l => {
    l.created_date = new Date(l.created_at).getTime();
  });

  const repOptions = Array.from(new Set(matchingLifts.map((lift) => lift.reps))).sort((a, b) => b - a);

  const handleLiftTypeChange = (event) => {
    const liftType = event.target.value;
    setSelectedLiftType(liftType);

    let noRepMatch = true;
    for (let i = 0; i < lifts.length; i++) {
      let l = lifts[i];
      if (l.lift_type === liftType && selectedReps === `${l.reps}`) {
        noRepMatch = false;
        break;
      }
    }

    if (noRepMatch) {
      setSelectedReps('');
    }
  };

  const handleRepsChange = (e) => {
    setSelectedReps(e.target.value);
  };

  const handleDateIntervalChange = (e) => {
    setDateInterval(e.target.value);
  }

  const handleChartTypeChange = (e) => {
    setChartType(e.target.value);
  }

  const handleWeightDateRangeChange = (e) => {
    setWeightDateRange(e.target.value);
  };

  const liftOptions = liftTypes.filter(lt => lt.active).map(lt => lt.name).map(liftType => {
    return <option key={liftType} value={liftType}>
      {liftType}
    </option>
  });

  return (
    <Flex width="100%" justifyContent="center">
      <Box maxWidth="800px" width="95%" height="400px" marginTop="20">
        <Flex justifyContent="flex-start" mb={8} mt={8} gap={5}>

          <Box>
            <FormLabel mb={3} fontSize='xs' color='gray.200'>Chart</FormLabel>
            <Select
              color='gray.300'
              borderColor='gray.500'
              value={chartType}
              onChange={handleChartTypeChange}
            >
              {CHART_OPTIONS}
            </Select>
          </Box>

          {![
            CHART_TYPE.LIFT_GRID,
            CHART_TYPE.WEIGHT,
            CHART_TYPE.MAX_PERCENT,
          ].includes(chartType) && (
              <Box>
                <FormLabel mb={3} fontSize='xs' color='gray.200'>Interval</FormLabel>
                <Select
                  color='gray.300'
                  borderColor='gray.500'
                  value={dateInterval}
                  onChange={handleDateIntervalChange}
                >
                  {INTERVAL_OPTIONS}
                </Select>
              </Box>
            )}


          {chartType !== CHART_TYPE.WEIGHT && (
            <Box>
              <FormLabel mb={3} fontSize='xs' color='gray.200'>Lift</FormLabel>
              <Select
                color='gray.300'
                borderColor='gray.500'
                value={selectedLiftType}
                onChange={handleLiftTypeChange}
              >
                {liftOptions}
              </Select>
            </Box>
          )}

          {chartType === CHART_TYPE.WEIGHT && (
            <Box>
              <FormLabel mb={3} fontSize='xs' color='gray.200'>Date Range</FormLabel>
              <Select
                color='gray.300'
                borderColor='gray.500'
                value={weightDateRange}
                onChange={handleWeightDateRangeChange}
              >
                {WEIGHT_DATE_RANGE_OPTIONS}
              </Select>
            </Box>
          )}


          {chartType === CHART_TYPE.MAX_REP && (
            <Box>
              <FormLabel mb={3} fontSize='xs' color='gray.200'>Reps</FormLabel>
              <Select
                color='gray.300'
                borderColor='gray.500'
                value={selectedReps}
                onChange={handleRepsChange}
              >
                <option value="">All</option>
                {repOptions.map((rep) => (
                  <option key={rep} value={rep}>
                    {rep}
                  </option>
                ))}
              </Select>
            </Box>
          )}

        </Flex>

        {chartType === CHART_TYPE.MAX_REP && (
          <MaxRepChart lifts={matchingLifts} dateInterval={dateInterval} repTarget={selectedReps} />
        )}
        {chartType === CHART_TYPE.VOLUME && (
          <VolumeChart lifts={matchingLifts} dateInterval={dateInterval} />
        )}
        {chartType === CHART_TYPE.LIFT_GRID && (
          <LiftGrid lifts={matchingLifts} />
        )}
        {chartType === CHART_TYPE.WEIGHT && (
          <WeightChart weights={weights} dateInterval={dateInterval} weightDateRange={weightDateRange} /> // Pass weightDateRange
        )}
        {chartType === CHART_TYPE.MAX_PERCENT && (
          <MaxPercentChart lifts={matchingLifts} />
        )}
      </Box>
    </Flex>
  );
};

const aggregateMaxRepData = (data, interval) => {
  const aggregatedData = [];
  const aggregationMap = {};

  data.forEach(item => {
    const date = new Date(item.created_date);
    let key;

    switch (interval) {
      case 'daily':
        key = date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
        break;
      case 'weekly':
        const startOfWeek = new Date(date);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
        key = startOfWeek.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
        break;
      case 'monthly':
        key = date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric' });
        break;
      default:
        key = date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
        break;
    }

    if (!aggregationMap[key]) {
      aggregationMap[key] = { weight: 0, estimated_one_rep_max: 0 };
    }

    const estimatedOneRepMax = estimateMaxRep(item.weight, item.reps)

    if (item.weight > aggregationMap[key].weight) {
      aggregationMap[key].weight = item.weight;
    }

    if (estimatedOneRepMax > aggregationMap[key].estimated_one_rep_max) {
      aggregationMap[key].estimated_one_rep_max = estimatedOneRepMax;
    }
  });

  for (const key in aggregationMap) {
    const dateParts = key.split('/');

    let dateKey;
    if (interval === 'monthly') {
      let month = parseInt(dateParts[0]) - 1;
      let year = parseInt(dateParts[1]);
      dateKey = new Date(year, month, 1).getTime();
    } else {
      let month = parseInt(dateParts[0]) - 1;
      let day = parseInt(dateParts[1]);
      let year = parseInt(dateParts[2]);
      dateKey = new Date(year, month, day).getTime();
    }

    aggregatedData.push({
      created_date: dateKey,
      weight: aggregationMap[key].weight,
      estimated_one_rep_max: aggregationMap[key].estimated_one_rep_max
    });
  }

  // Sort by date
  aggregatedData.sort((a, b) => a.created_date - b.created_date);

  return aggregatedData;
};

const MaxRepChart = ({ lifts, dateInterval, repTarget }) => {
  const theme = useTheme();

  const lineColor = theme.colors.primary[500];
  const estimatedMaxColor = theme.colors.coolGray[300];

  const gridLineColor = theme.colors.gray[200];
  const tickColor = theme.colors.gray[300];

  const targetLifts = lifts.filter(l => (repTarget ? l.reps === parseInt(repTarget, 10) : true))

  const aggregatedData = aggregateMaxRepData(targetLifts, dateInterval);

  const minY = Math.min(...aggregatedData.map((item) => item.weight)) * 0.95;
  const maxY = Math.max(...aggregatedData.map((item) => item.weight)) * 1.05;

  const yTicks = generateYticks(minY, maxY);

  const minX = aggregatedData[0]?.created_date;
  const maxX = aggregatedData[aggregatedData.length - 1]?.created_date;
  const xTicks = generateXTicks(minX, maxX, dateInterval);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={aggregatedData}
        margin={{ top: 5, right: -25, left: 0, bottom: 5 }}
      >
        <CartesianGrid stroke={gridLineColor} strokeDasharray="none" strokeOpacity={0.15} />
        <XAxis
          dataKey="created_date"
          height={60}
          tickFormatter={tick => dateFormatter(tick, dateInterval)}
          tick={{ fontSize: 12, fill: tickColor, dy: 5 }}
          tickLine={false}
          type='number'
          scale='time'
          domain={[Math.min(...xTicks), Math.max(...xTicks)]}
          interval='preserveStart'
          ticks={xTicks}
          stroke={gridLineColor}
          strokeOpacity={0.3}
        />
        <YAxis
          orientation="right"
          domain={[Math.min(...yTicks), Math.max(...yTicks)]}
          tickLine={false}
          axisLine={false}
          ticks={yTicks}
          tick={{ fontSize: 12, fill: tickColor }}
        />
        <Tooltip
          contentStyle={{
            backgroundColor: theme.colors.primary[900],
            borderColor: theme.colors.gray[500],
          }}
          labelStyle={{
            color: theme.colors.gray[200],
          }}
          itemStyle={{
            color: theme.colors.gray[200],
          }}
          labelFormatter={label => dateFormatter(label, dateInterval)}
        />
        <Line
          type="monotone"
          dataKey="weight"
          stroke={lineColor}
          strokeWidth={2}
          dot={false}
        />
        <Line
          type="monotone"
          dataKey="estimated_one_rep_max"
          name="est. 1RM"
          stroke={estimatedMaxColor}
          strokeWidth={1}
          strokeDasharray="5 4"
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const aggregateVolumeByInterval = (data, interval) => {
  const aggregatedData = [];
  const aggregationMap = {};

  data.forEach(item => {
    const date = new Date(item.created_date);
    let key;

    switch (interval) {
      case 'daily':
        key = date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
        break;
      case 'weekly':
        const startOfWeek = new Date(date);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
        key = startOfWeek.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
        break;
      case 'monthly':
        key = date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric' });
        break;
      default:
        key = date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
        break;
    }

    if (!aggregationMap[key]) {
      aggregationMap[key] = { volume: 0 };
    }

    aggregationMap[key].volume += item.weight * item.reps;
  });

  for (const key in aggregationMap) {
    const dateParts = key.split('/');

    let dateKey;
    if (interval === DATE_INTERVAL.MONTHLY) {
      // ['5', '2024']
      let month = parseInt(dateParts[0]) - 1;
      let year = parseInt(dateParts[1]);
      dateKey = new Date(year, month, 1).getTime();
    } else {
      // ['5', '21', '2024']
      let month = parseInt(dateParts[0]) - 1;
      let day = parseInt(dateParts[1])
      let year = parseInt(dateParts[2]);
      dateKey = new Date(year, month, day).getTime();
    }

    aggregatedData.push({
      created_date: dateKey,
      volume: aggregationMap[key].volume
    });
  }

  // Sort by date
  aggregatedData.sort((a, b) => a.created_date - b.created_date);

  return aggregatedData;
};

const roundThousands = (num) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k';
  }
  return num;
};

const VolumeChart = ({ lifts, dateInterval }) => {
  const theme = useTheme();
  const lineColor = theme.colors.primary[500];
  const gridLineColor = theme.colors.gray[200];
  const tickColor = theme.colors.gray[300];

  const aggregatedData = aggregateVolumeByInterval(lifts, dateInterval);

  const minX = aggregatedData[0]?.created_date;
  const maxX = aggregatedData[aggregatedData.length - 1]?.created_date;
  const xTicks = generateXTicks(minX, maxX, dateInterval);

  const minY = 0;
  const maxY = Math.max(...aggregatedData.map((item) => item.volume)) * 1.05;
  const yTicks = generateYticks(minY, maxY);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={aggregatedData}
        margin={{ top: 5, right: -20, left: 0, bottom: 5 }}
      >
        <CartesianGrid stroke={gridLineColor} strokeDasharray="none" strokeOpacity={0.15} />
        <XAxis
          dataKey="created_date"
          height={60}
          tickFormatter={tick => dateFormatter(tick, dateInterval)}
          tick={{ fontSize: 12, fill: tickColor, dy: 5 }}
          tickLine={false}
          type="number"
          scale="time"
          domain={[Math.min(...xTicks), Math.max(...xTicks)]}
          ticks={xTicks}
          stroke={gridLineColor}
          strokeOpacity={0.3}
        />
        <YAxis
          orientation="right"
          domain={[Math.min(...yTicks), Math.max(...yTicks)]}
          tickLine={false}
          axisLine={false}
          ticks={yTicks}
          tick={{ fontSize: 12, fill: tickColor }}
          tickFormatter={roundThousands}
        />
        <Tooltip
          contentStyle={{
            backgroundColor: theme.colors.primary[900],
            borderColor: theme.colors.gray[500],
          }}
          labelStyle={{
            color: theme.colors.gray[200],
          }}
          itemStyle={{
            color: theme.colors.gray[200],
          }}
          labelFormatter={label => dateFormatter(label, dateInterval)}
        />
        <Line
          type="monotone"
          dataKey="volume"
          stroke={lineColor}
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const LiftGrid = ({ lifts }) => {
  const theme = useTheme();
  const lineColor = theme.colors.gray[700];
  const headerColor = theme.colors.gray[400];
  const brightWhiteBorder = `solid ${theme.colors.primary[600]}`;

  const gridData = {};
  let maxFrequency = 0;

  const mostRecentDate = Math.max(...lifts.map(lift => new Date(getDateFromStr(lift.created_at)).getTime()));
  const recentLifts = lifts.filter(lift => new Date(getDateFromStr(lift.created_at)).getTime() === mostRecentDate);


  lifts.forEach(lift => {
    const weight = lift.weight;
    const reps = lift.reps;

    if (!gridData[weight]) {
      gridData[weight] = {};
    }

    if (!gridData[weight][reps]) {
      gridData[weight][reps] = 0;
    }

    gridData[weight][reps] += 1;
    if (gridData[weight][reps] > maxFrequency) {
      maxFrequency = gridData[weight][reps];
    }
  });

  const weights = Object.keys(gridData).map(Number).sort((a, b) => a - b);
  const maxReps = Math.max(...lifts.map(lift => lift.reps));

  const getGradientColor = (frequency) => {
    const ratio = frequency / maxFrequency;
    const minColor = [55, 55, 55];
    const white = [255, 255, 255];
    const color = minColor.map((start, index) => Math.round(start + ratio * (white[index] - start)));
    return `rgb(${color.join(',')})`;
  };

  const getBackgroundColor = (weight, rep) => {
    for (let w of weights) {
      if (w >= weight) {
        for (let r = rep; r <= maxReps; r++) {
          if (gridData[w][r] > 0) {
            return 'rgba(255, 255, 255, 0.05)';
          }
        }
      }
    }
    return 'transparent';
  };

  return (
    <Table variant="simple" size="sm" borderColor={lineColor} borderWidth="1px">
      <Thead>
        <Tr>
          <Th color={headerColor} textAlign="right" borderRight="1px solid" borderColor={lineColor}></Th>
          {[...Array(maxReps).keys()].map(rep => (
            <Th key={rep} color={headerColor} textAlign="right" borderRight="1px solid" borderColor={lineColor}>{rep + 1}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {weights.map(weight => (
          <Tr key={weight}>
            <Td color={headerColor} textAlign="right" borderRight="1px solid" borderColor={lineColor}>{weight}</Td>
            {[...Array(maxReps).keys()].map(rep => {
              const frequency = gridData[weight][rep + 1] || 0;
              const isRecentLift = recentLifts.some(lift => lift.weight === weight && lift.reps === rep + 1);
              return (
                <Td
                  key={rep}
                  textAlign="right"
                  borderRight="1px solid"
                  borderColor={lineColor}
                  style={{
                    color: getGradientColor(frequency),
                    backgroundColor: getBackgroundColor(weight, rep + 1),
                    border: isRecentLift ? brightWhiteBorder : undefined // Apply bright white border if it's a recent lift
                  }}
                >
                  {frequency > 0 ? frequency : ''}
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

// New WeightChart component
const WeightChart = ({ weights, dateInterval, weightDateRange }) => {
  const theme = useTheme();
  const lineColor = theme.colors.primary[500];
  const gridLineColor = theme.colors.gray[200];
  const tickColor = theme.colors.gray[300];

  // Calculate the start date based on the selected date range
  const now = new Date();
  let startDate;
  switch (weightDateRange) {
    case WEIGHT_DATE_RANGE.ONE_MONTH:
      startDate = new Date(now.setMonth(now.getMonth() - 1));
      break;
    case WEIGHT_DATE_RANGE.ONE_YEAR:
      startDate = new Date(now.setFullYear(now.getFullYear() - 1));
      break;
    case WEIGHT_DATE_RANGE.FIVE_YEARS:
      startDate = new Date(now.setFullYear(now.getFullYear() - 5));
      break;
    default:
      startDate = new Date(now.setMonth(now.getMonth() - 1));
  }

  // Aggregate weights by date and filter by date range
  const weightMap = {};

  weights.forEach(weight => {
    const createdDate = new Date(weight.created_at).getTime();
    if (createdDate >= startDate.getTime()) {
      const dateKey = new Date(createdDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
      if (!weightMap[dateKey] || weightMap[dateKey].created_date < createdDate) {
        weightMap[dateKey] = {
          created_date: createdDate,
          weight: weight.weight,
        };
      }
    }
  });

  // Convert map to array and sort by date
  const weightData = Object.values(weightMap).sort((a, b) => a.created_date - b.created_date);

  const minX = weightData[0]?.created_date;
  const maxX = weightData[weightData.length - 1]?.created_date;
  const xTicks = generateXTicks(minX, maxX, dateInterval);

  const minY = Math.min(...weightData.map((item) => item.weight)) * 0.95;
  const maxY = Math.max(...weightData.map((item) => item.weight)) * 1.05;
  const yTicks = generateYticks(minY, maxY);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={weightData}
        margin={{ top: 5, right: -20, left: 0, bottom: 5 }}
      >
        <CartesianGrid stroke={gridLineColor} strokeDasharray="none" strokeOpacity={0.15} />
        <XAxis
          dataKey="created_date"
          height={60}
          tickFormatter={tick => dateFormatter(tick, dateInterval)}
          tick={{ fontSize: 12, fill: tickColor, dy: 5 }}
          tickLine={false}
          type="number"
          scale="time"
          domain={[Math.min(...xTicks), Math.max(...xTicks)]}
          ticks={xTicks}
          stroke={gridLineColor}
          strokeOpacity={0.3}
        />
        <YAxis
          orientation="right"
          domain={[Math.min(...yTicks), Math.max(...yTicks)]}
          tickLine={false}
          axisLine={false}
          ticks={yTicks}
          tick={{ fontSize: 12, fill: tickColor }}
        />
        <Tooltip
          contentStyle={{
            backgroundColor: theme.colors.primary[900],
            borderColor: theme.colors.gray[500],
          }}
          labelStyle={{
            color: theme.colors.gray[200],
          }}
          itemStyle={{
            color: theme.colors.gray[200],
          }}
          labelFormatter={label => dateFormatter(label, dateInterval)}
        />
        <Line
          type="monotone"
          dataKey="weight"
          stroke={lineColor}
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const WEIGHT_PERCENTAGES = [100, 95, 90, 85, 80, 75, 70, 65];
const WEIGHT_ROUNDING = 5;
const MONTH_LOOKBACK = 6;
const MaxPercentChart = ({ lifts }) => {
  const theme = useTheme();
  const headerColor = theme.colors.gray[400];
  const lineColor = theme.colors.gray[700];
  const textColor = theme.colors.gray[200];

  // Calculate the date three months ago
  const threeMonthsAgo = new Date();
  const targetMonth = threeMonthsAgo.getMonth() - MONTH_LOOKBACK;
  const targetDate = new Date(threeMonthsAgo.getFullYear(), targetMonth, threeMonthsAgo.getDate());

  // Filter lifts from the last three months
  const recentLifts = lifts.filter(lift => new Date(getDateFromStr(lift.created_at)) >= targetDate);

  const highestEstimatedMaxRep = Math.max(
    ...recentLifts.map(lift => roundToNearestN(estimateMaxRep(lift.weight, lift.reps), WEIGHT_ROUNDING))
  );

  // Calculate the highest rep for each weight percentage, considering higher weights
  const maxRepsForWeights = WEIGHT_PERCENTAGES.map(percent => {
    const targetWeight = roundToNearestN(highestEstimatedMaxRep * (percent / 100), WEIGHT_ROUNDING);
    const matchingLifts = lifts.filter(lift => roundToNearestN(lift.weight, WEIGHT_ROUNDING) >= targetWeight);
    const maxRep = Math.max(...matchingLifts.map(lift => lift.reps), 0);
    return maxRep;
  });

  const tdStyle = { color: textColor, width: '33%' }

  return (
    <Table variant="simple" size="sm" borderColor={lineColor} borderWidth="1px" style={{ maxWidth: '500px', margin: '0 auto' }}>
      <Thead>
        <Tr>
          <Th color={headerColor} textAlign="right" borderRight="1px solid" borderColor={lineColor}>Percentage</Th>
          <Th color={headerColor} textAlign="right" borderRight="1px solid" borderColor={lineColor}>Weight</Th>
          <Th color={headerColor} textAlign="right" borderRight="1px solid" borderColor={lineColor}>Max Rep</Th>
        </Tr>
      </Thead>
      <Tbody>
        {WEIGHT_PERCENTAGES.map((percent, index) => (
          <Tr key={percent}>
            <Td
              textAlign="right"
              borderRight="1px solid"
              borderColor={lineColor}
              style={tdStyle}
            >
              {percent}%
            </Td>

            <Td
              textAlign="right"
              borderRight="1px solid"
              borderColor={lineColor}
              pt={4}
              pb={4}
              style={tdStyle}
            >
              {roundToNearestN(highestEstimatedMaxRep * (percent / 100), WEIGHT_ROUNDING)}
            </Td>

            <Td
              textAlign="right"
              borderRight="1px solid"
              borderColor={lineColor}
              pt={4}
              pb={4}
              style={tdStyle}
            >
              {maxRepsForWeights[index]}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default ChartPage;
