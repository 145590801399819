export const ROUTES = {
  HOME: '/',
  MAIN: '/main',
  LANDING: 'landing',
  SIGN_IN: '/sign-in',
  LIFT_CREATE: '/log-lift',
  LIFT_DETAIL_BASE: '/lift',
  LIFT_TEMPLATE_DETAIL: '/lift-template',
  SIGN_UP: '/sign-up',
  LIFT_TYPE_LIST: '/lift-type-list',
  WORKOUT_LIST: '/workout-list',
  LIFT_TEMPLATE_CREATE: '/lift-template-create',
  PLATE_MATH: '/plate-math',
  CHART: '/chart',
  GOAL_LIST: '/goal-list',
  GOAL_CREATE: '/goal-create',
  GOAL_DETAIL_BASE: '/goal',
  WEIGHT_LIST: '/weight-list',
  WEIGHT_CREATE: '/weight',
};
