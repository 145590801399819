import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Spacer,
  Select,
  NumberInput,
  NumberInputField,
  Button,
  HStack,
  Flex,
} from '@chakra-ui/react';
import { useDataManager } from '../../data/useDataManager';

function GoalDetail({ handleCreate, handleUpdate, handleDelete, goal }) {
  const [liftType, setLiftType] = useState(goal?.lift_type || '');
  const [weight, setWeight] = useState(goal?.weight || 0);
  const [reps, setReps] = useState(goal?.reps || '');

  const { liftTypes } = useDataManager();

  const handleLiftTypeChange = (event) => {
    setLiftType(event.target.value);
  };

  const handleWeightChange = (value) => {
    setWeight(value);
  };

  const handleRepsChange = (value) => {
    setReps(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!liftType || !weight || !reps) {
      alert('Requires lift type, weight, and reps');
      return;
    }

    handleCreate(liftType, weight, reps);
  };

  const liftTypeOptions = liftTypes.map((liftType) => (
    <option key={liftType.id} value={liftType.name}>
      {liftType.name}
    </option>
  ));

  return (
    <Flex width="100%" justifyContent="center">
      <Box maxWidth="600px" width="100%" mt={6} pl={30} pr={30}>
        <Box as="form" onSubmit={handleSubmit}>
          <FormControl pt={10} mb={5} display="block">
            <FormLabel>Lift Type</FormLabel>
            <Select
              placeholder="Select lift type"
              value={liftType}
              onChange={handleLiftTypeChange}
              size="lg"
            >
              {liftTypeOptions}
            </Select>
          </FormControl>

          <FormControl mb={5} display="block">
            <FormLabel>Weight</FormLabel>
            <NumberInput
              min={0}
              max={3000}
              defaultValue={weight || undefined}
              onChange={handleWeightChange}
            >
              <NumberInputField />
            </NumberInput>
          </FormControl>

          <FormControl mb={5} display="block">
            <FormLabel>Reps</FormLabel>
            <NumberInput min={0} max={200} defaultValue={reps} onChange={handleRepsChange}>
              <NumberInputField />
            </NumberInput>
          </FormControl>

          <HStack mt={5}>
            {goal ? (
              <>
                <Button onClick={() => handleUpdate(goal.id, liftType, weight, reps)} colorScheme="blue" size="lg">
                  Update
                </Button>
                <Spacer />
                <Button onClick={() => handleDelete(goal.id)} colorScheme="red" size="lg">
                  Delete
                </Button>
              </>
            ) : (
              <Button type="submit" colorScheme="blue" size="lg">
                Create
              </Button>
            )}
          </HStack>
        </Box>
      </Box>
    </Flex>
  );
}

export default GoalDetail;
