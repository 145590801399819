import { Button, FormLabel, Input, Select, Spinner, Switch } from "@chakra-ui/react";

export function ModalButton({ color, bg, loadingBg, text, onClick, mr, isLoading, isDisabled }) {
  return (
    <Button
      mr={mr}
      h='md'
      width='2lg'
      fontWeight='semibold'
      color={color}
      bg={isLoading ? loadingBg : bg}
      _hover={{
        bg: loadingBg,
      }}
      onClick={onClick}
      isDisabled={isDisabled}
    >
      {!isLoading ? text : <Spinner />}
    </Button>
  );
}

const inputProps = {
  mt: 6,
  h: 'md',
  color: 'gray.100',
  bg: 'coolGray.600',
  borderColor: 'coolGray.500',
  fontSize: 'sm',
  sx: {
    colorSchme: 'dark',
  }
}

export function InputLabel({ name }) {
  return <FormLabel
    htmlFor={name} mb={0} w="30%" flexShrink={0} color='gray.100'>
    {name}
  </FormLabel>
}

export function NumericInput({ value, onChange }) {
  return <Input
    {...inputProps}
    type='number'
    value={value}
    onChange={onChange}
  />
}

export function TextInput({ value, onChange }) {
  return <Input
    {...inputProps}
    value={value}
    onChange={onChange}
  />
}

export function SwitchInput({ isChecked, onChange }) {
  return <Switch
    size='lg'
    h='md'
    mt={6}
    colorScheme='primary'
    isChecked={isChecked}
    onChange={onChange}
  />
}

export function SelectInput({ value, placeholderText, options, onChange }) {
  return <Select
    {...inputProps}
    onChange={onChange}
    placeholder={placeholderText}
    value={value}
  >
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </Select>
}
