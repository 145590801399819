import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Spinner } from '@chakra-ui/react';

import GoalDetail from "./GoalDetail";
import { ROUTES } from "../../config/routes";
import { useDataManager } from "../../data/useDataManager";

function GoalEditPage() {
  const { goalId } = useParams();
  const navigate = useNavigate();
  const dataManager = useDataManager();

  const [goal, setGoal] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleUpdate = async (id, liftType, weight, reps) => {
    try {
      const updatedGoal = {
        id: id,
        lift_type: liftType,
        weight: weight,
        reps: reps,
      };
      await dataManager.updateGoal(updatedGoal);
      navigate(ROUTES.GOAL_LIST);
    } catch (error) {
      console.error("Failed to update the goal:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await dataManager.deleteGoal(id);
      navigate(ROUTES.GOAL_LIST);
    } catch (error) {
      console.error("Failed to delete the goal:", error);
    }
  };

  useEffect(() => {
    const fetchGoal = async (goalId) => {
      const { goal, error } = await dataManager.getGoal(goalId);
      if (!error) {
        setGoal(goal);
      }
      setIsLoading(false);
    };

    const selectedGoal = dataManager.goals.filter((goal) => goal.id === goalId);
    if (selectedGoal.length === 1) {
      console.log("fetching from manager");
      setGoal(selectedGoal[0]);
      setIsLoading(false);
    } else {
      fetchGoal(goalId);
    }
  }, [goalId, dataManager]);

  if (!goalId) {
    return <>Invalid goal url</>;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!goal) {
    return <>This goal does not exist</>;
  }

  return (
    <GoalDetail
      goal={goal}
      handleUpdate={handleUpdate}
      handleDelete={handleDelete}
    />
  );
}

export default GoalEditPage;
