import {
  Box,
  Flex,
  IconButton,
  Spacer,
  Text,
} from '@chakra-ui/react';

import { useLocation } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import { ROUTES } from '../../config/routes';
import { useNavBarContext } from './NavBarContext';

const pageTitle = {
  [ROUTES.MAIN]: 'Home',
  [ROUTES.CHART]: 'Charts',
  [ROUTES.LIFT_TYPE_LIST]: 'Lift Settings',
}

export default function NavBar({ iconClickRef, onIconClick }) {
  const location = useLocation();
  const { navBarElement } = useNavBarContext();

  const title = pageTitle[location.pathname] || 'Unknown';

  return (
    <Flex
      bg='primary.900'
      h='lg'
      alignItems="center"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex="sticky"
      borderBottom="1px"
      borderColor="gray.600"
    >

      <Box h='lg' w='lg' display='flex' alignItems='center' justifyContent='center'>
        <IconButton
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="unstyled"
          color="white"
          onClick={onIconClick}
          ref={iconClickRef}
        />
      </Box>

      <Text color='gray.100' fontSize="md" fontWeight="bold" mt='3'>{title}</Text>

      <Spacer />

      {navBarElement}

    </Flex>
  );
}
