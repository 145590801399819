import { useContext, useState, useEffect, createContext, useMemo } from 'react';
import { useAuth } from '../hooks/useAuth';
import { supabase } from '../supabaseClient';

const DataManagerContext = createContext(undefined);

const LIFT_LOG_TABLE = 'lift_log';
const LIFT_ID = 'id';
const LIFT_TYPE_ID = 'id';

const LIFT_TYPE_TABLE = 'lift_type';

const WORKOUT_TABLE = 'workout';
const WORKOUT_ID = 'id';

const LIFT_TEMPLATE_TABLE = 'lift_template';
const LIFT_TEMPLATE_ID = 'id';

const GOAL_TABLE = 'goal';
const GOAL_ID = 'id';

const WEIGHT_TABLE = 'weight';
const WEIGHT_ID = 'id';

const DEFAULT_LIFT_TYPES = {
  'bench press': 'bp',
  'squat': 'sq',
  'deadlift': 'dl',
  'overhead press': 'ohp'
}


export const DataManagerProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const [lifts, setLifts] = useState([]);
  const [liftTypes, setLiftTypes] = useState([]);
  const [liftTypesLoading, setLiftTypesLoading] = useState(true);
  const [workouts, setWorkouts] = useState([]);
  const [liftTemplates, setLiftTemplates] = useState([]);
  const [goals, setGoals] = useState([]);
  const [weights, setWeights] = useState([]);

  const auth = useAuth();

  const sortedLiftTypes = useMemo(() => {
    const liftsWithDate = lifts.reduce((acc, lift) => {
      if (!acc[lift.lift_type] || new Date(acc[lift.lift_type].created_at) < new Date(lift.created_at)) {
        acc[lift.lift_type] = lift;
      }
      return acc;
    }, {});

    return liftTypes.slice().sort((a, b) => {
      // eslint-disable-next-line
      const bDate = liftsWithDate[b.name]?.created_at;
      // eslint-disable-next-line
      const aDate = liftsWithDate[a.name]?.created_at;
      return new Date(bDate).getTime() - new Date(aDate).getTime();
    });
  }, [lifts, liftTypes]);

  const fetchLifts = async () => {
    const { data, error } = await supabase.from(LIFT_LOG_TABLE)
      .select(`
        id,
        lift_type_id,
        created_at,
        user_id,
        weight,
        reps,
        notes,
        status,
        lift_type_info:lift_type (name)
      `)
      .order('created_at', { ascending: false })
      .limit(2000);

    if (error) {
      console.error('failed to get lifts');
      return;
    }

    data.forEach(lift => {
      // for compatibility with how this object was previously used
      lift.lift_type = lift.lift_type_info.name;
    })

    setLifts(data);
    localStorage.setItem('lifts', JSON.stringify(data));
  }

  const createLift = async (liftType, liftTypeId, weight, reps, notes, date) => {
    const { error } = await supabase
      .from(LIFT_LOG_TABLE)
      .insert({ user_id: auth.user.id, lift_type_id: liftTypeId, lift_type: liftType, weight, reps, notes, created_at: date });

    if (!error) {
      await fetchLifts()
    }

    return { error };
  };

  const createLifts = async (lifts) => {
    const liftsToCreate = lifts.map(l => {
      l.user_id = auth.user.id;
      return l;
    });

    const { error } = await supabase
      .from(LIFT_LOG_TABLE)
      .insert(liftsToCreate);

    if (error) {
      return { error };
    }

    await fetchLifts()
    return { error: null }
  };

  const deleteLift = async (liftId) => {
    const { error } = await supabase
      .from(LIFT_LOG_TABLE)
      .delete()
      .eq(LIFT_ID, liftId)

    if (!error) {
      await fetchLifts()
    }

    return { error };
  };

  const updateLift = async (lift) => {
    const { error } = await supabase
      .from(LIFT_LOG_TABLE)
      .update(lift)
      .eq(LIFT_ID, lift.id);

    if (!error) {
      await fetchLifts()
    }

    return { error };
  };

  const getLift = async (liftId) => {
    const { data } = await supabase.from(LIFT_LOG_TABLE).select().eq(LIFT_ID, liftId).order('created_at', { ascending: false });


    if (data && data.length === 1) {
      return {
        lift: data[0],
        error: null,
      }
    } else {
      return {
        lift: null,
        error: 'Lift not found',
      }
    }
  }

  const uploadLiftVideo = async (liftId, file, url) => {
    const fileReader = new FileReader();

    fileReader.onloadend = async () => {
      const arrayBuffer = fileReader.result;
      const blobData = new Blob([new Uint8Array(arrayBuffer)], { type: 'application/octet-stream' });

      const result = await fetch(url, {
        method: 'PUT',
        body: blobData,
      });

      if (result.ok) {
        console.error('File uploaded successfully');
      } else {
        console.error('Error uploading file:');
        console.error('Error uploading file:', result.statusText);
      }
    };

    fileReader.readAsArrayBuffer(file);

    return {
      error: null,
    }
  }

  const fetchLiftTypes = async () => {
    const { data, error } = await supabase.from(LIFT_TYPE_TABLE).select();

    if (error) {
      console.error('failed to get lift types');
    } else if (data) {
      setLiftTypes(data);
      setLiftTypesLoading(false);
      localStorage.setItem('lift_types', JSON.stringify(data));
    }
  }

  const deleteLiftType = async (liftTypeId) => {
    const { error } = await supabase
      .from(LIFT_TYPE_TABLE)
      .delete()
      .eq(LIFT_ID, liftTypeId)

    if (!error) {
      await fetchLiftTypes()
    }

    return { error };
  };

  const createLiftType = async (liftType, shortName) => {
    const { error } = await supabase
      .from(LIFT_TYPE_TABLE)
      .insert({ user_id: auth.user.id, name: liftType, short_name: shortName });

    if (!error) {
      await fetchLiftTypes()
      return;
    }
    if (error.code === "23505") {
      return 'This lift type already exists';
    }
  };

  const updateLiftType = async (liftType) => {
    if (!liftType.name) {
      return 'Lift type cannot be empty'
    }

    const { error } = await supabase
      .from(LIFT_TYPE_TABLE)
      .update(liftType)
      .eq(LIFT_TYPE_ID, liftType.id)

    if (!error) {
      await fetchLiftTypes();
      await fetchLifts();
      return;
    }
    if (error.code === "23505") {
      return 'This lift type already exists';
    }
    return 'There was some other issue';
  };

  const fetchWorkouts = async () => {
    const { data, error } = await supabase.from(WORKOUT_TABLE).select();
    if (error) {
      console.error('failed to get workouts');
    }
    setWorkouts(data);
  }

  const createWorkout = async (name) => {
    const { error } = await supabase
      .from(WORKOUT_TABLE)
      .insert({ user_id: auth.user.id, name });

    if (!error) {
      await fetchWorkouts()
    }

    return { error };
  };

  const deleteWorkout = async (workoutId) => {
    const { error } = await supabase
      .from(WORKOUT_TABLE)
      .delete()
      .eq(WORKOUT_ID, workoutId)

    if (!error) {
      await fetchWorkouts()
    }

    return { error };
  };

  const editWorkout = async (workoutId, newName) => {
    const { error } = await supabase
      .from(WORKOUT_TABLE)
      .update({ user_id: auth.user.id, name: newName })
      .eq(WORKOUT_ID, workoutId)

    if (!error) {
      await fetchWorkouts()
    }

    return { error };
  };

  const startWorkout = async (workoutId) => {
    const workout = workouts.find(w => w.id === workoutId);
    if (!workout) {
      return { error: 'Workout not found' };
    }

    const liftTemplatesToCreate = liftTemplates.filter(lt => lt.workout_id === workoutId);
    console.log(`Creating lifts with templates ${JSON.stringify(liftTemplatesToCreate)}`)

    const liftsToCreate = [];
    for (const lt of liftTemplatesToCreate) {
      for (let i = 0; i < lt.sets; i++) {
        liftsToCreate.push({
          user_id: auth.user.id,
          lift_type: lt.name,
          weight: lt.weight,
          reps: lt.reps,
          created_at: new Date(),
          status: 'TODO',
        })
      }
    }

    const { error } = await createLifts(liftsToCreate);
    if (error) {
      return { error };
    }

    await fetchLifts();

    return {
      error: null,
    }
  }


  const fetchGoals = async () => {
    const { data, error } = await supabase.from(GOAL_TABLE).select();
    if (error) {
      console.error('failed to get goals');
    }
    setGoals(data);
  }

  const createGoal = async (liftType, weight, reps) => {
    const { error } = await supabase
      .from(GOAL_TABLE)
      .insert({ user_id: auth.user.id, lift_type: liftType, weight, reps });

    if (!error) {
      await fetchGoals();
    }

    return { error };
  };

  const deleteGoal = async (goalId) => {
    const { error } = await supabase
      .from(GOAL_TABLE)
      .delete()
      .eq(GOAL_ID, goalId);

    if (!error) {
      await fetchGoals();
    }

    return { error };
  };

  const getGoal = async (goalId) => {
    const { data } = await supabase.from(GOAL_TABLE).select().eq(GOAL_ID, goalId);

    if (data && data.length === 1) {
      return {
        lift: data[0],
        error: null,
      }
    } else {
      return {
        lift: null,
        error: 'Goal not found',
      }
    }
  }


  const updateGoal = async (goal) => {
    const { error } = await supabase
      .from(GOAL_TABLE)
      .update(goal)
      .eq(GOAL_ID, goal.id);

    if (!error) {
      await fetchGoals();
    }

    return { error };
  };


  const fetchWeights = async () => {
    const { data, error } = await supabase.from(WEIGHT_TABLE).select();
    if (error) {
      console.error('failed to get weights');
    }
    setWeights(data);
  }

  const createWeight = async (weight, date) => {
    const { error } = await supabase
      .from(WEIGHT_TABLE)
      .insert({ user_id: auth.user.id, weight: weight, created_at: date });

    if (!error) {
      await fetchWeights();
    }

    return { error };
  };

  const deleteWeight = async (weightId) => {
    const { error } = await supabase
      .from(WEIGHT_TABLE)
      .delete()
      .eq(WEIGHT_ID, weightId);

    if (!error) {
      await fetchWeights();
    }

    return { error };
  };


  const fetchLiftTemplates = async () => {
    const { data, error } = await supabase.from(LIFT_TEMPLATE_TABLE).select();
    if (error) {
      console.error('failed to get lift templates');
    }
    setLiftTemplates(data);
  }

  const createLiftTemplate = async (workoutId, name, weight, sets, reps) => {
    const { error } = await supabase
      .from(LIFT_TEMPLATE_TABLE)
      .insert({ user_id: auth.user.id, workout_id: workoutId, name, weight, sets, reps });

    if (!error) {
      await fetchLiftTemplates()
    }

    return { error };
  };

  const deleteLiftTemplate = async (liftTemplateId) => {
    const { error } = await supabase
      .from(LIFT_TEMPLATE_TABLE)
      .delete()
      .eq(LIFT_TEMPLATE_ID, liftTemplateId)

    if (!error) {
      await fetchLiftTemplates()
    }

    return { error };
  };

  const updateLiftTemplate = async (liftTemplate) => {
    const { error } = await supabase
      .from(LIFT_TEMPLATE_TABLE)
      .update(liftTemplate)
      .eq(LIFT_TEMPLATE_ID, liftTemplate.id);

    if (!error) {
      await fetchLiftTemplates()
    }

    return { error };
  };

  const initCachedLifts = async () => {
    if (lifts.length === 0 && liftTypes.length === 0) {
      const cachedLifts = localStorage.getItem('lifts');
      const cachedLiftTypes = localStorage.getItem('lift_types');
      if (cachedLifts && cachedLifts.length > 0 && cachedLiftTypes && cachedLiftTypes.length > 0) {
        setLifts(JSON.parse(cachedLifts));
        setLiftTypes(JSON.parse(cachedLiftTypes));
      }
    }
  }

  // on startup or auth change
  useEffect(() => {
    const init = async () => {
      await initCachedLifts()
      await Promise.all([
        fetchLifts(),
        fetchLiftTypes(),
        fetchWorkouts(),
        fetchLiftTemplates(),
        fetchGoals(),
        fetchWeights(),
      ]);
      setLoading(false);
    }
    if (auth.user) {
      init();
    }
  }, [auth]);

  useEffect(() => {
    const createDefaultLiftTypes = async () => {
      const { error } = await supabase
        .from(LIFT_TYPE_TABLE)
        .insert(Object.entries(DEFAULT_LIFT_TYPES).map(([name, shortName]) => ({ user_id: auth.user.id, name: name, short_name: shortName })));

      // fk violation / user was deleted
      if (error && error.code === "23503") {
        auth.signOut();
      }

      return { error };
    }

    const check = async () => {
      if (!liftTypesLoading && liftTypes.length === 0) {
        const { error } = await createDefaultLiftTypes();
        if (error) {
          console.log(error);
        }
        // idempotent if error
        await fetchLiftTypes();
      }
    }
    if (auth.user) {
      check();
    }
  }, [liftTypesLoading, liftTypes, auth])

  const value = {
    // TODO: Replace user? with user AND test an error handling event
    // eslint-disable-next-line
    userId: auth?.user?.id,

    lifts,
    loading,
    createLift,
    createLifts,
    deleteLift,
    updateLift,
    getLift,
    uploadLiftVideo,

    liftTypes,
    sortedLiftTypes,
    deleteLiftType,
    createLiftType,
    updateLiftType,

    workouts,
    createWorkout,
    deleteWorkout,
    editWorkout,
    startWorkout,
    fetchWorkouts,

    liftTemplates,
    createLiftTemplate,
    deleteLiftTemplate,
    updateLiftTemplate,
    fetchLiftTemplates,

    goals,
    createGoal,
    deleteGoal,
    updateGoal,
    getGoal,

    weights,
    createWeight,
    deleteWeight,
  };


  const placeHolder = {
    userId: undefined,

    lifts: [],
    loading: true,
    createLift: () => { },
    createLifts: () => { },
    deleteLift: () => { },
    updateLift: () => { },
    getLift() { },
    uploadLiftVideo: () => { },

    liftTypes: [],
    sortedLiftTypes: [],
    deleteLiftType: () => { },
    createLiftType: () => { },
    updateLiftType: () => { },

    workouts: [],
    createWorkout: () => { },
    deleteWorkout: () => { },
    editWorkout: () => { },
    startWorkout: () => { },
    fetchWorkouts: () => { },

    liftTemplates: [],
    createLiftTemplate: () => { },
    deleteLiftTemplate: () => { },
    updateLiftTemplate: () => { },
    fetchLiftTemplates: () => { },

    goals: [],
    createGoal: () => { },
    deleteGoal: () => { },
    updateGoal: () => { },
    getGoal: () => { },

    weights: [],
    createWeight: () => { },
    deleteWeight: () => { },
  }

  return (
    <DataManagerContext.Provider value={auth.user ? value : placeHolder}>
      {children}
    </DataManagerContext.Provider>
  );
};

export const useDataManager = () => {
  return useContext(DataManagerContext);
};
