import {
  Box,
  Divider,
  Button,
  Stack,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Text,
  Input,
  useToast,
} from "@chakra-ui/react";

import React, { useState } from "react";
import { FcGoogle } from 'react-icons/fc';
import { API_ENDPOINTS } from "../../config/api";
import { useAuth } from "../../hooks/useAuth";
import useCreateAccount from "../../hooks/useCreateAccount";
import { ToastAlert } from "../common/ToastAlert";

const DividerWithText = ({ children }) => (
  <Stack direction="row" alignItems="center" mb={7} pt={7}>
    <Divider borderColor="gray.200" flex="1" />
    <Text color="gray.200" px={2}>
      {children}
    </Text>
    <Divider borderColor="gray.200" flex="1" />
  </Stack>
);

const SignInButton = ({ onClick, title }) => {
  return <Button
    type="submit"
    bg='primary.600'
    _hover={{ bg: "primary.500" }}
    color='gray.100'
    fontWeight='regular'
    size="lg"
    w="100%"
    mt={8}
    onClick={onClick}
  >
    {title}
  </Button>
}

function SignInPageV2({ isCreateMode }) {
  const auth = useAuth();
  const toast = useToast()

  const { createAccount } = useCreateAccount(API_ENDPOINTS.POST_CREATE_ACCOUNT);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');

  const onError = (message) => {
    toast({
      status: 'error',
      position: 'top',
      duration: 3000,
      isClosable: false,
      render: () => <ToastAlert status='error' message={message} />,
    });
  }


  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  }

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  }

  const handleChangeVerifyPassword = (event) => {
    setVerifyPassword(event.target.value);
  }

  const onLoginClick = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      onError('Requires email and password');
      return;
    }

    const { error } = await auth.signIn(email, password);
    if (!!error) {
      onError('Login failed');
    }
  };

  const onGoogleLoginClick = async (event) => {
    const { error } = await auth.googleSignIn();
    if (error) {
      onError('Failed to sign in');
    }
  }

  const handleCreateAccountClick = async (event) => {
    event.preventDefault();

    if (!email || !password || !verifyPassword) {
      onError('Must fill out all fields');
      return;
    }

    if (password !== verifyPassword) {
      onError('Passwords must match');
      return;
    }

    const { error } = await createAccount({
      email: email,
      password: password,
    })
    if (!!error) {
      toast({
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: false,
        render: () => <ToastAlert status='error' message='Failed to create account' />,
      });
    } else {
      toast({
        position: 'top',
        duration: 2 * 60 * 1000, // 2 mins
        isClosable: false,
        render: () => <ToastAlert status='success' message='Check your email to verify you account!' />,
      })
    }
  };

  return <>
    <Flex direction='column' p={30} h="100%" justifyContent="center">

      <Flex mb={10} w='100%' justifyContent='center'>
        <Heading
          as="h1"
          fontSize='2.5xl'
          fontWeight={600}
          textAlign="center"
          color="gray.100"
          sx={{
            fontFamily: "Montserrat"
          }}
        >
          Simple Lifting App
        </Heading>
      </Flex>


      <Flex w='100%' justifyContent='center'>
        <Box w='4lg' as="form">
          <FormControl display="block" isRequired>
            <FormLabel mb={3} color='gray.100'>Email</FormLabel>
            <Input color='gray.200' type="email" onChange={handleChangeEmail} />
          </FormControl>

          <FormControl mt={5} display="block" isRequired>
            <FormLabel mb={3} color='gray.100'>Password</FormLabel>
            <Input color='gray.200' type="password" onChange={handleChangePassword} />
          </FormControl>

          {isCreateMode &&
            <FormControl mt={5} display="block" isRequired>
              <FormLabel mb={3} color='gray.100'>Verify Password</FormLabel>
              <Input color='gray.200' type="password" onChange={handleChangeVerifyPassword} />
            </FormControl>
          }

          {isCreateMode
            ? <SignInButton title='Create Account' onClick={handleCreateAccountClick} />
            : <SignInButton title='Login' onClick={onLoginClick} />
          }

          <DividerWithText>or</DividerWithText>

          <Button
            leftIcon={
              <Box mr='5px'>
                <FcGoogle size="24px" />
              </Box>
            }
            bg="gray.100"
            color="gray.700"
            fontFamily="'Roboto', sans-serif"
            fontWeight="500"
            fontSize='md'
            size="lg"
            w="100%"
            _hover={{ bg: 'gray.200' }}
            _active={{ bg: 'gray.200' }}
            boxShadow="0 1px 2px 0 rgba(66,133,244,0.3)"
            onClick={onGoogleLoginClick}
          >
            <Text as="span">Login In with Google</Text>
          </Button>

        </Box>


      </Flex>
    </Flex>
  </>;
}

export default SignInPageV2;
