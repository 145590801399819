
import React from "react";
import { useParams } from "react-router-dom";

import LiftTemplate from "./LiftTemplate";

function LiftTemplateCreatePage() {
  const { workoutId } = useParams();
  return <LiftTemplate workoutId={workoutId} />
}

export default LiftTemplateCreatePage;
