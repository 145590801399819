import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/routes";
import { useDataManager } from "../../data/useDataManager";
import WeightDetail from "./WeightDetail";

function WeightCreatePage() {
  const navigate = useNavigate();
  const dataManager = useDataManager();

  const handleCreate = async (weight, date) => {
    const { error } = await dataManager.createWeight(weight, date);

    if (error) {
      alert('Failed to create goal');
    } else {
      navigate(ROUTES.WEIGHT_LIST);
    }
  };

  return <WeightDetail
    handleCreate={handleCreate}
  />
}

export default WeightCreatePage;
