import React from "react";
import {
  Button,
  Box,
  Spacer,
  HStack,
  Divider,
} from "@chakra-ui/react";
import { useDataManager } from "../../data/useDataManager";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/routes";
import { useParams } from "react-router-dom";
import { DeleteIcon } from '@chakra-ui/icons';

function WorkoutDetailPage() {
  const dataManager = useDataManager();
  const navigate = useNavigate();
  const { workoutId } = useParams();

  const { liftTemplates: allLiftTemplates } = dataManager;
  const liftTemplates = allLiftTemplates.filter(lt => lt.workout_id === workoutId);

  const onLiftTemplateClick = (liftTemplateId) => {
    navigate(`${ROUTES.LIFT_TEMPLATE_DETAIL}/${liftTemplateId}`);
  }

  const handleAddLiftClicked = () => {
    navigate(`${ROUTES.LIFT_TEMPLATE_CREATE}/${workoutId}`);
  }

  const handleStartWorkoutClicked = async () => {
    const { error } = await dataManager.startWorkout(workoutId);
    if (error) {
      alert('There was an issue starting this workout');
      console.error(error);
      return;
    }
    navigate(ROUTES.MAIN);
  }

  const handleDeleteClicked = (liftTemplateId) => {
    if (workoutId) {
      const { error } = dataManager.deleteLiftTemplate(liftTemplateId);
      if (error) {
        alert('There was an issue deleting this workout');
      }
    }
  }

  const formatLiftTemplate = (lt) => {
    const reps = [];
    for (let i = 0; i < lt.sets; i++) {
      reps.push(lt.reps);
    }
    return ` ${lt.weight} x ${reps.join(", ")}`
  }

  return <>
    <Box pt='60px' pb='60px'>

      {liftTemplates.map(liftTemplate => {
        return <Box key={liftTemplate.id}>
          <HStack py={5} px={5} alignItems="center">
            <Box w='100%' textTransform="capitalize" onClick={() => onLiftTemplateClick(liftTemplate.id)}>
              <b>{liftTemplate.name}</b>
              {formatLiftTemplate(liftTemplate)}
            </Box>
            <Spacer />
            <DeleteIcon onClick={() => handleDeleteClicked(liftTemplate.id)} />
          </HStack>
          <Divider />
        </Box>
      })}

      <Box
        position="fixed"
        bottom={0}
        left={0}
        w="100%"
        p={1}
        zIndex={2}
        bg='white'
        style={{ display: 'flex' }}
      >
        <HStack w='300px' mx='auto'>

          <Button
            type="submit"
            colorScheme="blue"
            size="lg"
            w="150px"
            onClick={handleStartWorkoutClicked}
            mx="auto"
          >
            Start Workout
          </Button>
          <Spacer />

          <Button
            colorScheme="green"
            size="lg"
            w="150px"
            onClick={handleAddLiftClicked}
            mx="auto"
          >
            Add Lift
          </Button>
        </HStack>
      </Box>
    </Box>
  </>
}

export default WorkoutDetailPage;
