import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/routes";
import { useDataManager } from "../../data/useDataManager";
import GoalDetail from "./GoalDetail";

function GoalCreatePage() {
  const navigate = useNavigate();
  const dataManager = useDataManager();

  const handleCreate = async (liftType, weight, reps) => {
    const { error } = await dataManager.createGoal(liftType, weight, reps);

    if (error) {
      alert('Failed to create goal');
    } else {
      navigate(ROUTES.GOAL_LIST);
    }
  };

  return <GoalDetail
    handleCreate={handleCreate}
  />
}

export default GoalCreatePage;
