
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Spinner } from '@chakra-ui/react';

import LiftDetail from "./LiftDetail";
import { ROUTES } from "../../config/routes";
import { useDataManager } from "../../data/useDataManager";
import useTrackAction from "../metrics/useTrackAction";
import { LIFT_COPY, LIFT_DELETE, LIFT_UPDATE } from "../metrics/constants";

function LiftDetailPage() {
  const { liftId } = useParams();
  const navigate = useNavigate();
  const dataManager = useDataManager();
  const { userId } = dataManager;

  const [lift, setLift] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { trackStart, trackSuccess, trackFail } = useTrackAction();

  const handleDelete = async (liftId) => {
    const eventId = trackStart(LIFT_DELETE, userId);
    const { error } = await dataManager.deleteLift(liftId);
    if (error) {
      trackFail(eventId);
      alert('Failed to delete lift');
    } else {
      trackSuccess(eventId);
      navigate(ROUTES.HOME);
    }
  };

  const handleUpdate = async (lift) => {
    const eventId = trackStart(LIFT_UPDATE, userId);
    const { error } = await dataManager.updateLift(lift);
    if (error) {
      trackFail(eventId);
      alert('Failed to update lift');
    } else {
      trackSuccess(eventId);
      navigate(ROUTES.HOME);
    }
  };

  const handleClone = async (liftType, weight, reps, notes) => {
    const eventId = trackStart(LIFT_COPY, userId);
    const { error } = await dataManager.createLift(liftType, weight, reps, notes);

    if (error) {
      trackFail(eventId);
      alert('Failed to copy lift');
    } else {
      trackSuccess(eventId);
      navigate(ROUTES.HOME);
    }
  };

  useEffect(() => {
    const fetchLift = async (liftId) => {
      const { lift, error } = await dataManager.getLift(liftId)
      if (!error) {
        setLift(lift);
      }
      setIsLoading(false);
    }

    const selectLift = dataManager.lifts.filter(lift => lift.id === liftId);
    if (selectLift.length === 1) {
      console.log('fetching from manager')
      setLift(selectLift[0]);
      setIsLoading(false);
    } else {
      fetchLift(liftId);
    }

  }, [liftId, dataManager]);

  if (!liftId) {
    return <>Invalid lift url</>
  }

  if (isLoading) {
    return <Spinner />
  }

  if (!lift) {
    return <>This lift does not exist</>
  }

  return <LiftDetail
    lift={lift}
    handleDelete={handleDelete}
    handleUpdate={handleUpdate}
    handleCreate={handleClone}
  />
}

export default LiftDetailPage;
