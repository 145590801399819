import React from "react";
import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Tr,
  useColorModeValue,
  VStack,
  Spinner,
  Spacer,
  useToast,
  Text,
} from "@chakra-ui/react";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  ResponsiveContainer,
  YAxis,
  Tooltip,
  Line
} from 'recharts'
import { DeleteIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useDataManager } from "../../data/useDataManager";
import { ROUTES } from "../../config/routes";
import { format } from "date-fns";

function WeightListPage() {
  const navigate = useNavigate();
  const dataManager = useDataManager();
  const toast = useToast();

  const handleLogWeight = () => {
    navigate(ROUTES.WEIGHT_CREATE);
  };

  const onDeleteClicked = async (weightEntry) => {
    const { error } = await dataManager.deleteWeight(weightEntry.id);
    if (error) {
      alert("Failed to delete weight entry");
      return;
    }
    toast({
      title: "Weight Entry Deleted!",
      status: "success",
      position: "top",
      duration: 2500,
      isClosable: false,
    });
  };

  const { weights, loading } = dataManager; // Replace with actual data

  const bgColor = useColorModeValue("gray.50", "gray.900");

  if (loading) {
    return <Spinner />;
  }

  const sortedWeights = [...weights].sort(
    (a, b) => new Date(a.created_at) - new Date(b.created_at)
  );

  const data = sortedWeights.map((entry) => ({
    date: format(new Date(entry.created_at), "MM/dd/yyyy"),
    weight: entry.weight,
  }));

  const dateFormatter = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}`;
  };

  const minY = Math.min(...data.map((item) => item.weight)) - 10;
  const maxY = Math.max(...data.map((item) => item.weight)) + 20;

  return (
    <>
      <VStack
        pt={{ base: "60px", md: "80px" }}
        pb={{ base: "60px", md: "80px" }}
        bg={bgColor}
        minH="100vh"
        spacing={8}
      >

        <Flex width="100%" justifyContent="center">
          <Box maxWidth="600px" width="100%" height="400px" marginTop="20">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={data}
                margin={{ top: 5, right: 30, left: -30, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  height={60}
                  tickFormatter={dateFormatter}
                />
                <YAxis
                  domain={[minY, maxY]}
                  tickLine={false}
                  tick={{ fontSize: 10 }}
                />
                <Tooltip labelFormatter={dateFormatter} />
                <Line
                  type="monotone"
                  dataKey="weight"
                  stroke="#8884d8"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Flex>

        <Table size="md" variant="simple">
          <Tbody>
            {sortedWeights.reverse().map((entry) => {
              const entryDate = new Date(entry.created_at);
              const formattedDate = format(entryDate, "MM/dd/yyyy")
              return (
                <Tr key={entry.id}>
                  <Td>
                    <Flex py={1} px={5} alignItems="center">
                      <Box>
                        <Text fontWeight="bold">{formattedDate}</Text>
                      </Box>
                      <Box ml={5}>{entry.weight} lbs</Box>
                      <Spacer />
                      <DeleteIcon onClick={() => onDeleteClicked(entry)} />
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Box
          position="fixed"
          bottom={0}
          left={0}
          w="100%"
          p={1}
          zIndex={2}
          bg="white"
          borderTop="1px solid"
          borderColor="gray.200"
          style={{ display: "flex" }}
        >
          <Button
            type="submit"
            colorScheme="blue"
            size="lg"
            w="250px"
            onClick={handleLogWeight}
            mx="auto"
          >
            Log Weight
          </Button>
        </Box>
      </VStack>
    </>
  );
}

export default WeightListPage;
