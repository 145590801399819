import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';


export function StyledModal({ title, body, footer, isOpen, onClose, closeOnOverlayClick }) {
  const customMaxWidth = { base: "100%", sm: '600px', md: "600px" };
  const modalMargins = 7;

  return (
    <>
      <Modal isOpen={isOpen} closeOnOverlayClick={closeOnOverlayClick} onClose={onClose} isCentered autoFocus={false}>
        <ModalOverlay />
        <ModalContent
          bg='coolGray.800'
          mt={10}
          mx={modalMargins}
          maxW={customMaxWidth}
          border="1px solid"
          borderColor="coolGray.600"
        >

          <ModalHeader
            fontSize='lg'
            fontWeight='semibold'
            color='gray.100'
          >
            {title}
          </ModalHeader>

          <ModalBody p={6}>
            {body}
          </ModalBody>

          <ModalFooter p={6}>
            {footer}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
