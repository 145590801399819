import React, { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Flex, FormControl, FormLabel, useDisclosure, useToast } from '@chakra-ui/react';
import { MainPageContainer, LiftSelect, LogButton, LogFormContainer, LiftFreeFormInput, FormAlert, RecentLiftsContainer, WorkoutSummaryModal, LiftTypeSummaryModal } from './MainPageV2Components';
import { extractLift, isSetXRep, buildLiftsToCreate, todaysDate, capitalize, parseUserInput2 } from '../../utils/utils';
import { useDataManager } from '../../data/useDataManager';
import { EditLiftModal } from '../common/EditLiftModal';
import { DateInput } from '../common/DateInput';
import { ToastAlert } from '../common/ToastAlert';
import confetti from 'canvas-confetti';
import usePostRequest from '../../hooks/usePostRequest';
import { API_BASE_URL } from '../../config/api';
import { useAuth } from '../../hooks/useAuth';


const DEFAULT_FREE_FORM_PLACEHOLDER = "Enter Your Workout";
const RECENT_LIFT_DAYS = 365;

function MainPageV2() {
  const dataManager = useDataManager();
  const auth = useAuth();

  const toast = useToast()
  const location = useLocation();

  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure()
  const { isOpen: isWorkoutSummaryOpen, onOpen: onWorkoutSummaryOpen, onClose: onWorkoutSummaryClose } = useDisclosure()
  const { isOpen: isLiftTypeLogsOpen, onOpen: onLiftTypeLogsOpen, onClose: onLiftTypeLogsClose } = useDisclosure()

  const [active, setActive] = useState(false);
  const [freeFormInput, setFreeFormInput] = useState('');
  const [freeFormPlaceholder, setFreeFormPlaceholder] = useState(DEFAULT_FREE_FORM_PLACEHOLDER);
  const [currentLift, setCurrentLift] = useState('');
  const [showFormError, setShowFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [liftDate, setLiftDate] = useState(todaysDate());
  const [modalLift, setModalLift] = useState(null);
  const [workSummayModal, setWorkoutSummaryModal] = useState(null);
  const [workoutSummaryInfo, setWorkoutSummaryInfo] = useState({});
  const [activeLiftTypeLog, setActiveLiftTypeLog] = useState(null);

  const [creatingLift, setCreatingLift] = useState(false);

  const { postRequest } = usePostRequest(`${API_BASE_URL}/withings/exchange-code`);

  const { lifts, liftTypes } = dataManager;

  const liftOptions = liftTypes.filter(lt => lt.active).map(lt => {
    return {
      value: lt.id,
      label: capitalize(lt.name),
    };
  })

  const recentLifts = lifts.filter(l => {
    const liftDate = new Date(l.created_at);
    const today = new Date();
    const tenDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - RECENT_LIFT_DAYS);
    return liftDate >= tenDaysAgo;
  });

  const onLiftDelete = async (liftId) => {
    await dataManager.deleteLift(liftId);
  }

  const onLiftUpdate = async (lift) => {
    await dataManager.updateLift(lift);
  }

  const onDateChange = (e) => {
    const newDate = e.target.value;
    setLiftDate(newDate);
  }

  const onLogLift = async () => {
    const lifts = parseUserInput2(freeFormInput, liftTypes)

    if (!lifts || lifts.length === 0) {
      setShowFormError(true);
      setErrorMessage([
        'Requires a valid format. e.g.',
        '- sq 250 3 x 5',
        '- dl 250/10 270/5',
      ]);
      return;
    }

    if (!liftDate) {
      setShowFormError(true);
      setErrorMessage([
        'This is an invalid date'
      ]);
      return;
    }

    if (liftDate > todaysDate()) {
      setShowFormError(true);
      setErrorMessage([
        'Cannot log a date in the future',
      ]);
      return;
    }

    setCreatingLift(true);

    const liftsToCreate = buildLiftsToCreate(lifts, liftDate);
    await dataManager.createLifts(liftsToCreate);

    toast({
      position: 'top',
      duration: 2500,
      isClosable: false,
      render: () => <ToastAlert status='success' message='Lift has been logged' />,
    })

    // TODO: add this back after implementing goals
    // confetti({
    //   particleCount: 100,
    //   spread: 70,
    //   origin: { y: 0.6 }
    // });

    setCreatingLift(false);
    setFreeFormInput('');
    setActive(false);
    setLiftDate(todaysDate());
    setCurrentLift('');
    setFreeFormPlaceholder(DEFAULT_FREE_FORM_PLACEHOLDER);
  }

  const onFreeFormInputFocus = () => {
    setActive(true)
    setFreeFormPlaceholder('example: sq 255 3 x 5');
  };

  const onFreeFormClear = () => {
    setFreeFormInput('');
    setActive(false)
    setFreeFormPlaceholder(DEFAULT_FREE_FORM_PLACEHOLDER);
    setCurrentLift('');
    setErrorMessage([]);
    setLiftDate(todaysDate());
    setShowFormError(false);
  }

  const onFreeFormInputChange = (e) => {
    const userInput = e.target.value;
    const lifts = parseUserInput2(userInput, liftTypes)

    if ((lifts || lifts.length === 0) && showFormError) {
      setShowFormError(false);
    }

    if (isSetXRep(userInput) && userInput.length > freeFormInput.length) {
      setFreeFormInput(userInput + "x ");
    } else {
      setFreeFormInput(userInput);
    }

    const userLiftTypeId = extractLift(userInput, liftTypes);

    if (userLiftTypeId) {
      setCurrentLift(userLiftTypeId);
      return
    }
    setCurrentLift('');
  }

  const onLiftChange = (e) => {
    const selectedLiftId = e.target.value;

    const matchingTypes = liftTypes.filter(lt => lt.id === selectedLiftId);
    if (matchingTypes.length === 0) {
      return;
    }

    const matchedLift = matchingTypes[0];

    if (!freeFormInput) {
      setFreeFormInput(matchedLift.short_name);
    } else {
      const words = freeFormInput.split(" ");
      words[0] = matchedLift.short_name;
      const updatedText = words.join(" ");
      setFreeFormInput(updatedText);
    }
    setCurrentLift(matchedLift.id);
  }

  const onCardClick = (liftType, lifts) => {
    setActiveLiftTypeLog(liftType);
    onLiftTypeLogsOpen();
  }

  const onPillClick = (lift) => {
    setModalLift(lift);
    onEditModalOpen();
  }

  const onDateClick = (lifts) => {
    const liftEntries = Object.entries(lifts);
    const workoutSummary = liftEntries.reduce((acc, [liftType, liftArray]) => {
      const total = liftArray.reduce((sum, lift) => sum + (lift.reps * lift.weight), 0);
      acc[liftType] = total;
      return acc;
    }, {});

    setWorkoutSummaryInfo(workoutSummary);
    setWorkoutSummaryModal(true);
    onWorkoutSummaryOpen();
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const authorizationCode = queryParams.get('code');

    if (authorizationCode) {
      console.log('Authorization Code:', authorizationCode);
      exchangeAuthorizationCodeForTokens(authorizationCode);
    }


  }, [location]);

  const exchangeAuthorizationCodeForTokens = async (code) => {
    try {
      const { data, error } = await postRequest({
        code: code,
      }, auth?.session.access_token)

      if (error) {
        console.error('Fetch error when calling /withings/exchange-code', error);
        toast({
          position: 'top',
          duration: 2500,
          isClosable: false,
          render: () => <ToastAlert status='error' message='Issue syncing with Withings' />,
        })
        return
      }

      toast({
        position: 'top',
        duration: 2500,
        isClosable: false,
        render: () => <ToastAlert status='success' message='Successfully synced with Withings' />,
      })

    } catch (error) {
      console.error('Error exchanging authorization code:', error);
    }
  };

  return <MainPageContainer>
    {modalLift && <EditLiftModal lift={modalLift} liftTypes={liftTypes} isOpen={isEditModalOpen} onClose={onEditModalClose} onDelete={onLiftDelete} onUpdate={onLiftUpdate} />}
    {workSummayModal && <WorkoutSummaryModal workoutSummary={workoutSummaryInfo} isOpen={isWorkoutSummaryOpen} onModalClose={onWorkoutSummaryClose} />}
    {activeLiftTypeLog && <LiftTypeSummaryModal lifts={lifts} liftType={activeLiftTypeLog} isOpen={isLiftTypeLogsOpen} onModalClose={onLiftTypeLogsClose} />}

    <LiftFreeFormInput
      value={freeFormInput}
      placeholder={freeFormPlaceholder}
      onChange={onFreeFormInputChange}
      onFocus={onFreeFormInputFocus}
      onClear={onFreeFormClear}
      active={active}
    />

    <LogFormContainer active={active}>
      <FormControl>
        <FormLabel color='gray.100' fontSize='sm'>Lift</FormLabel>
        <LiftSelect placeholderText='Select Lift' value={currentLift} options={liftOptions} onChange={onLiftChange} />
      </FormControl>

      <FormControl mt={7}>
        <FormLabel color='gray.100' fontSize='sm'>Date</FormLabel>
        <DateInput bg='coolGray.800' date={liftDate} maxDate={todaysDate()} handleDateChange={onDateChange} />
      </FormControl>

      <FormAlert isVisible={showFormError} message={errorMessage} />

      <Flex justifyContent="flex-end" mt={9}>
        <LogButton text='Log Lift' onClick={onLogLift} isSpinner={creatingLift} />
      </Flex>
    </LogFormContainer>
    <RecentLiftsContainer lifts={recentLifts} active={active} onCardClick={onCardClick} onPillClick={onPillClick} onDateClick={onDateClick} />
  </MainPageContainer>
}

export default MainPageV2;
