
import React from "react";
import { useParams } from "react-router-dom";
import { useDataManager } from "../../data/useDataManager";

import LiftTemplate from "./LiftTemplate";

function LiftTemplateDetailPage() {
  const { liftTemplateId } = useParams();
  const dataManager = useDataManager();
  const { liftTemplates } = dataManager;

  const liftTemplate = liftTemplates.filter(lt => lt.id === liftTemplateId)[0];

  if (!liftTemplate) {
    return <>This lift template does not exist</>
  }

  return <LiftTemplate workoutId={liftTemplate.workout_id} liftTemplate={liftTemplate} />
}

export default LiftTemplateDetailPage;
