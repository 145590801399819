
import React from 'react';

import { Flex, Divider, Link, Text, Container, Box } from '@chakra-ui/react';

const FooterV2 = () => (
  <Box color='gray.600' h='lg' as="footer" pt={10}>
    <Divider borderColor='gray.800' />
    <Container maxW="container.md">
      <Flex justifyContent="space-between" alignItems="center" py={4}>
        <Text fontSize="xs">
          Contact: <a href="mailto: support@simpleliftingapp.com">support@simpleliftingapp.com</a>
        </Text>

        <Flex gap={6}>
          <Link fontSize='xs' href='privacy-policy.html' >
            Privacy Policy
          </Link>
          <Link fontSize='xs' href='tos.html'>Terms of Service</Link>
        </Flex>
      </Flex>
    </Container>
  </Box>
);

export default FooterV2;
