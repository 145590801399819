
import { css } from '@emotion/react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  Divider,
  Flex,
  Grid,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { capitalize, formatDateString, formatSixDigitDate } from '../../utils/utils';
import { StyledModal } from '../common/StyledModal';

export function MainPageContainer({ children }) {
  return (<>
    <Flex justifyContent="center">
      <VStack
        h="100dvh"
        w='100%'
        maxWidth='2xl'
        p={7}
        justify="center"
        spacing={8}
      >
        <>
          {children}
        </>
      </VStack>
    </Flex>
  </>);
}


export function LiftSelect({ options, placeholderText, value, onChange }) {
  return <Select
    mt={4}
    h='md'
    color='gray.100'
    bg='coolGray.800'
    borderColor='coolGray.500'
    fontSize='sm'
    onChange={onChange}
    placeholder={placeholderText}
    value={value}
  >
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </Select>
}

// TODO: probably worth re-using ModalButton
export function LogButton({ text, onClick, isSpinner }) {
  return <Button
    w='2lg'
    fontSize='md'
    h='md'
    bg={isSpinner ? 'primary.800' : "primary.600"}
    color='gray.100'
    fontWeight='normal'
    borderRadius="lg"
    _hover={{ bg: "primary.500" }}
    onClick={onClick}
    isDisabled={isSpinner}
  >
    {isSpinner ? <Spinner size="sm" color="gray.100" /> : text}
  </Button>
}

const formTransitionStyles = css`
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    fetransition: max-height 0.3s ease, opacity 0.3s ease;

    &.form-active {
      opacity: 1;
      max-height: 100%;
    }
  `;
export function LogFormContainer({ children, active }) {
  if (!active) {
    return undefined;
  }
  return <Box w='100%' css={formTransitionStyles} className={active ? 'form-active' : ''}>
    {children}
  </Box>
}

export function LiftFreeFormInput({ value, placeholder, onChange, onFocus, onClear, active }) {
  return <InputGroup>
    <Input
      fontSize="lg"
      color="gray.100"
      fontWeight="thin"
      _placeholder={{ color: 'gray.100' }}
      border="none"
      borderBottom="1px"
      paddingLeft={0}
      borderRadius={0}
      _focus={{
        boxShadow: "none",
        borderBottom: "1px",
        borderColor: "gray.100",
        _placeholder: { color: 'gray.500' }
      }}

      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={onFocus}
    />
    {active && <InputRightElement children={<CloseButton color="gray.300" size='sm' onClick={onClear} />} />}
  </InputGroup>
}

export function FormAlert({ isVisible, message }) {
  return isVisible && <Box mt={8}>

    <Alert p={5} status='error' borderRadius='lg'>
      <AlertIcon ml={3} mr={6} color="error.500" />
      <AlertDescription color="error.500">
        {message.map((line, i) => <Text key={i}>{line}</Text>)}
      </AlertDescription>
    </Alert>
  </Box>
}

const groupLiftsByDate = (lifts) => {
  return lifts.reduce((groups, lift) => {
    const date = new Date(lift.created_at).toLocaleDateString();
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(lift);
    return groups;
  }, {});
};


const groupLiftsByType = (lifts) => {
  return lifts.reduce((groups, lift) => {
    const type = lift.lift_type;
    if (!groups[type]) {
      groups[type] = [];
    }
    groups[type].push(lift);
    return groups;
  }, {});
};

export function RecentLiftsContainer({ lifts, active, onCardClick, onPillClick, onDateClick }) {
  if (active || lifts.length === 0) {
    return undefined;
  }

  const liftsByDate = groupLiftsByDate(lifts);

  return <Box w='100%' maxHeight='60%' pt={4}>
    {Object.entries(liftsByDate).map(([date, lifts]) =>
      <RecentLiftsDateSection key={date} date={date} lifts={lifts} onCardClick={onCardClick} onPillClick={onPillClick} onDateClick={onDateClick} />
    )}
  </Box>
}

function RecentLiftsDateSection({ date, lifts, onCardClick, onPillClick, onDateClick }) {
  const groupedLifts = groupLiftsByType(lifts);

  const dateClickHandler = () => {
    onDateClick(groupedLifts);
  }

  return <>
    <Text onClick={dateClickHandler} color='gray.100' fontSize='md' fontWeight='regular' mt={8}>
      {formatDateString(date)}
    </Text>
    <VStack spacing={2}>
      {Object.entries(groupedLifts).map(([liftType, lifts]) =>
        <RecentLiftCard
          key={liftType}
          liftType={liftType}
          lifts={lifts}
          onCardClick={onCardClick}
          onPillClick={onPillClick}
        />)}
    </VStack>
  </>
}

export function WorkoutSummaryModal({ workoutSummary, isOpen, onModalClose }) {
  const sortedWorkoutSummary = Object.entries(workoutSummary).sort((a, b) => b[1] - a[1]);

  return <StyledModal
    title={'Summary'}
    body={
      <Flex flexDirection="column" gap={4}>
        {sortedWorkoutSummary.map(([liftType, total], index) => (
          <Flex key={index} align="center" justifyContent="space-between">
            <Text color='gray.100'>{capitalize(liftType)}</Text>
            <Text color='gray.100'>{total.toLocaleString('en-US')}</Text>
          </Flex>
        ))}
      </Flex>
    }
    footer={
      <></>
    }
    isOpen={isOpen}
    closeOnOverlayClick={true}
    onClose={onModalClose}
  />
}


export function LiftTypeSummaryModal({ lifts, liftType, isOpen, onModalClose }) {
  const targetLifts = lifts.filter(l => l.lift_type_info.name === liftType);

  const groupedLifts = targetLifts.reduce((acc, lift) => {
    const date = formatSixDigitDate(lift.created_at);
    if (!acc[date]) acc[date] = [];
    acc[date].push(lift);
    return acc;
  }, {});

  const sortedDates = Object.keys(groupedLifts).sort((a, b) => new Date(b) - new Date(a));

  const sortedLifts = sortedDates.map(date => ({
    date,
    lifts: groupedLifts[date].sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
      .map(lift => `${lift.weight} / ${lift.reps}`)
  }));

  return <StyledModal
    title={
      < Text pt={5}>{capitalize(liftType)}</Text>
    }
    body={
      <Flex flexDirection="column" gap={6}>
        {sortedLifts.map((l) =>
          <>
            <Divider borderColor='gray.600' />
            <HStack justifyContent="space-between">
              <Box>

                <Text color='gray.100'>
                  {l.date}
                </Text>
              </Box>
              <Box>

                {l.lifts.map(weightRep =>
                  <Text textAlign='center' maxW='lg' color='gray.100'>
                    {weightRep}
                  </Text>
                )}

              </Box>
            </HStack>
          </>
        )}
      </Flex>
    }
    footer={
      <></>
    }
    isOpen={isOpen}
    closeOnOverlayClick={true}
    onClose={onModalClose}
  />
}

function RecentLiftCard({ liftType, lifts, onCardClick, onPillClick }) {
  const handleClick = () => {
    onCardClick(liftType, lifts);
  }


  return (
    <Box
      w='100%'
      mt={6}
      p={6}
      border='1px'
      borderColor='gray.400'
      borderStyle='solid'
      borderRadius='2lg'
      onClick={handleClick}
    >
      <Text color='gray.200' fontSize='sm' >
        {capitalize(liftType)}
      </Text>
      <Pills lifts={lifts} onPillClick={onPillClick} />
    </Box>
  );
}

function Pills({ lifts, onPillClick }) {
  const sortedLifts = lifts.sort((a, b) => {
    return new Date(a.created_at) - new Date(b.created_at);
  });

  return <Grid
    templateColumns="repeat(auto-fill, minmax(80px, 1fr))"
    gap={6}
    pt={6}
  >
    {sortedLifts.map(l => <SetPill key={l.id} lift={l} onClick={onPillClick} />)}
  </Grid>
}

function SetPill({ lift, onClick }) {
  // Function to handle click event and stop it from bubbling up
  const handleClick = (e) => {
    e.stopPropagation();
    onClick(lift);
  }

  return (
    <Box
      display="flex"
      borderRadius='2lg'
      bg='coolGray.700'
      justifyContent="center"
      alignItems="center"
      h='md'
      onClick={handleClick}
    >
      <Text color='gray.100' fontSize='sm'>
        {lift.weight} x {lift.reps}
      </Text>
    </Box>
  );
}
