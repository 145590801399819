import { css } from '@emotion/react';
import {
  Input
} from '@chakra-ui/react';

export function DateInput({ date, maxDate, handleDateChange, bg }) {
  return <Input
    mt={4}
    h='md'
    color='gray.100'
    bg={bg}
    borderColor='coolGray.500'
    fontSize='sm'
    sx={{ colorScheme: 'dark' }}
    type="date"
    css={css`
            &::-webkit-date-and-time-value {
              text-align: left;
            }
          `}
    onChange={handleDateChange}
    value={date}
    max={maxDate}
  />
}
