import React from "react";
import { ROUTES } from "../../config/routes";
import { useNavigate } from "react-router-dom";
import LiftDetail from "./LiftDetail";
import { useDataManager } from "../../data/useDataManager";
import useTrackAction from "../metrics/useTrackAction";
import { LIFT_CREATE } from "../metrics/constants";

function LiftCreatePage() {
  const navigate = useNavigate();
  const dataManager = useDataManager();
  const { userId } = dataManager;

  const { trackStart, trackSuccess, trackFail } = useTrackAction();

  const handleCreate = async (liftType, liftTypeId, weight, reps, notes, date) => {

    const eventId = trackStart(LIFT_CREATE, userId)

    const { error } = await dataManager.createLift(liftType, liftTypeId, weight, reps, notes, date);

    if (error) {
      trackFail(eventId);
      alert('Failed to log lift');
    } else {
      trackSuccess(eventId);
      navigate(ROUTES.HOME);
    }
  };

  return <LiftDetail
    handleCreate={handleCreate}
  />
}

export default LiftCreatePage;
