import { useState } from "react";

// TODO: refactor this to be more generic
function useCreateAccount(url) {
  const [loading, setLoading] = useState(true);

  const createAccount = async (postData) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application.json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
      })
      const data = await response.json();
      // setData(data);
      setLoading(false);
      return {
        data: data,
        error: null,
      };
    } catch (error) {
      console.error("Error posting data:", error);
      setLoading(false);
      return {
        data: null,
        error,
      }
    }
  };


  return { createAccount, loading };
}

export default useCreateAccount;
