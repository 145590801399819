import { useState } from "react";

function usePostRequest(url) {
  const [loading, setLoading] = useState(true);

  const postRequest = async (postData, accessToken) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application.json',
          'Content-Type': 'application/json',
          ...(accessToken ? { 'Authorization': `Bearer ${accessToken}` } : {})
        },
        body: JSON.stringify(postData),
      })

      if (!response.ok) {
        const data = await response.json();
        setLoading(false);
        return {
          error: data.error,
        }
      }

      const data = await response.json();
      setLoading(false);
      return {
        data: data,
        error: null,
      };
    } catch (error) {
      console.error("Error posting data:", error);
      setLoading(false);
      return {
        data: null,
        error,
      }
    }
  };

  return { postRequest, loading };
}

export default usePostRequest;
