import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Button,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { format } from 'date-fns';

function WeightDetail({ handleCreate }) {
  const [weight, setWeight] = useState(0);
  const [date, setDate] = useState(new Date());

  const handleDateChange = (event) => setDate(new Date(event.target.value));
  const handleWeightChange = (value) => setWeight(value);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!weight) {
      alert('Weight is required');
      return;
    }

    handleCreate(weight, date);
  };

  const defaultDate = format(date, "yyyy-MM-dd");

  return (
    <Flex width='100%' justifyContent='center'>
      <Box maxWidth='600px' width='100%' mt={6} pl={30} pr={30}>
        <FormControl pt={10} mb={5} display='block'>
          <FormLabel>Date</FormLabel>
          <Input
            placeholder='Select Date'
            size='md'
            type='date'
            value={defaultDate}
            onChange={handleDateChange}
          />
        </FormControl>

        <Box as='form' onSubmit={handleSubmit}>
          <FormControl mb={5} display='block'>
            <FormLabel>Weight</FormLabel>
            <NumberInput min={0} max={1000} defaultValue={weight || undefined} onChange={handleWeightChange}>
              <NumberInputField />
            </NumberInput>
          </FormControl>

          <Button type='submit' colorScheme='blue' size='lg'>
            Create
          </Button>
        </Box>
      </Box>
    </Flex>
  );
}

export default WeightDetail;
